import React, { useState } from 'react';
import placeholder_2 from "../../../Assets/placeholder_2.svg";

import "./style.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NW1 from "../../../Assets/NW1.png";
import NW2 from "../../../Assets/NW2.png";
import NW3 from "../../../Assets/NW3.png";
import NW4 from "../../../Assets/NW4.png";
import newatch1 from "../../../Assets/watches/newatch1.png";
import newatch2 from "../../../Assets/watches/newatch2.png";
import newatch4 from "../../../Assets/watches/newatch4.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { formatIndianCurrency } from './../../../helpers';
import placeholder_image from "../../../Assets/placeholder.svg";
import { backend_url } from "../../../Path";
import { Link } from 'react-router-dom';
import { CustomLoader } from './../../../helpers';

const NewWatch = ({preOwnedProducts,brandNewProducts,loading,likeNew}) => {
  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const [value, setValue] = React.useState("Brand New/Unworn");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="section4 new_watches_section">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value} centered>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="New Watches" value="Brand New/Unworn" className="tabTitle" />
              <Tab label="Pre Owned" value="Pre Owned" className="tabTitle" />
              <Tab label="Under Retail" value="3" className="tabTitle" />
            </TabList>
          </Box>
          <TabPanel value="Brand New/Unworn" className="newwatches_tabs">
          {brandNewProducts.length <=0 && <div style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
          <div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
                  <img src={placeholder_2} height='auto' width='200px'/>
                </div>
            </div>}
          {  loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:

<Carousel
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              responsive={responsive}
              >
              {/* <div className="col container2">
                <img className="newWatchimg" src={newatch1} alt="rolex" />
                <div className="newIn">
                  <p>New In</p>
                </div>
                <h6>Rolex</h6>
                <p>
                  Sea-Dweller 43mm
                </p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </div> */}
              
              {brandNewProducts.map((product) => (loading ? <div style={{width:'100%'}}><CustomLoader /> </div>:
        <div className="col container2" key={product.id}>
          <Link to={`/productdetail/#${product.id}`}>
          {/* Display product image */}
          <img
              className="newWatchimg"
              src={`${backend_url}products/uploads/medium/${product.main_image}` }
              onError={(e) => { 
                e.target.onerror = null; 
                e.target.src = placeholder_image
              }}   // Use first image or a default image
              alt={product.name}
            />
          
          <div className="newIn">
                  <p>New In</p>
                </div>
          
          {/* Display brand name */}
          <h6>{product.brand}</h6>
          
          {/* Display product name */}
          <p>{product.name}</p>
          
          {/* Display product price with formatted currency */}
          {/* <h5>from {formatIndianCurrency(product.price)}</h5> */}
          <h5>from {(product.api_price == null) ? formatIndianCurrency(product.price):formatIndianCurrency(product.api_price)}</h5>
          </Link>
        </div>
      ))}
  
            </Carousel>
}
<div>
            <div className='shop-btn'>
             <Link to="/products#brandNew">   <a href='#' className='btne commonbtnanimation'>DISCOVER MORE</a></Link>
            </div>
        </div>
          </TabPanel>
          <TabPanel value="Pre Owned" className="newwatches_tabs">
          {preOwnedProducts.length <=0 && <div style={{display:'flex', alignItems:'center',justifyContent:'center'}}> <div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
                  <img src={placeholder_2} height='auto' width='200px'/>
                </div></div>}

          {  loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:

            <Carousel
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              responsive={responsive}
            >
             {preOwnedProducts.map((product) => (loading ? <div style={{width:'100%'}}><CustomLoader /> </div>:
        <div className="col container2" key={product.id}>
          <Link to={`/productdetail/#${product.id}`}>

          {/* Display product image */}
          <img
              className="newWatchimg"
              src={`${backend_url}products/uploads/medium/${product.main_image}` }
              onError={(e) => { 
                e.target.onerror = null; 
                e.target.src = placeholder_image
              }}   // Use first image or a default image
              alt={product.name}
            />
          
          <div className="newIn">
                  <p>New In</p>
                </div>
          
          {/* Display brand name */}
          <h6>{product.brand}</h6>
          
          {/* Display product name */}
          <p>{product.name}</p>
          
          {/* Display product price with formatted currency */}
          {/* <h5>from {formatIndianCurrency(product.price)}</h5> */}
          <h5>from {(product.api_price == null) ? formatIndianCurrency(product.price):formatIndianCurrency(product.api_price)}</h5>
          </Link>
        </div>
      ))}
            </Carousel>
}
<div>
            <div className='shop-btn'>
             <Link to="/products#preOwned">   <a href='#' className='btne commonbtnanimation'>DISCOVER MORE</a></Link>
            </div>
        </div>
          </TabPanel>
          <TabPanel value="3" className="newwatches_tabs">
          {likeNew.length <=0 && <div style={{display:'flex', alignItems:'center',justifyContent:'center'}}><div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
                  <img src={placeholder_2} height='auto' width='200px'/>
                </div></div>}

{  loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
            <Carousel
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              responsive={responsive}
            >
                {likeNew.map((product) => (loading ? <div style={{width:'100%'}}><CustomLoader /> </div>:
        <div className="col container2" key={product.id}>
          <Link to={`/productdetail/#${product.id}`}>

          {/* Display product image */}
          <img
              className="newWatchimg"
              src={`${backend_url}products/uploads/medium/${product.main_image}` }
              onError={(e) => { 
                e.target.onerror = null; 
                e.target.src = placeholder_image
              }}   // Use first image or a default image
              alt={product.name}
            />
          
          <div className="newIn">
                  <p>New In</p>
                </div>
          
          {/* Display brand name */}
          <h6>{product.brand}</h6>
          
          {/* Display product name */}
          <p>{product.name}</p>
          
          {/* Display product price with formatted currency */}
          {/* <h5>from {formatIndianCurrency(product.price)}</h5> */}
          <h5>from {(product.api_price == null) ? formatIndianCurrency(product.price):formatIndianCurrency(product.api_price)}</h5>
          </Link>
        </div>
      ))}
            </Carousel>
}
<div>
            <div className='shop-btn'>
             <Link to="/products#underRetail">   <a href='#' className='btne commonbtnanimation'>DISCOVER MORE</a></Link>
            </div>
        </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default NewWatch;

import React, { useEffect, useState } from "react";
import './style.css';
import banner_new from '../../../Assets/banner_new.jpg'
import whatsap from "../../../Assets/whatsap.png";


const Hero = ({Globalwhatsapp}) => {

  const [bannerImage, setBannerImage] = useState(banner_new);
  const [randomnum, setRandomnum] = useState(6);

  // List of banner images
  const bannerImages = [
    banner_new
    // banner2,
    // banner3,
    // banner4,
  ];

  // Function to generate a random index
  const getRandomIndex = () => {
    return Math.floor(Math.random() * bannerImages.length);
  };

  // Function to set the banner image state with a random image
  const setRandomBannerImage = () => {
    const randomIndex = getRandomIndex();
    setBannerImage(bannerImages[randomIndex]);
  };

  const generateRandomInteger = (min, max) => {
    // return Math.floor(min + Math.random()*(max - min + 1))
    return 1;
  }

  // Set random banner image when component mounts or page refreshes
  useEffect(() => {
    setRandomBannerImage();
    let num = generateRandomInteger(1, 6);
    setRandomnum(num);
    console.log(num);
  }, []);

  return (
    <>
    <div className={`hero-section banner`+randomnum}>
      <div className="hero-heading">
        <h1 className="hero-title">BUY. SELL. TRADE. CONSIGN. LEARN.</h1>
        <p className="hero-text1">THE UNPARALLELED EXPERIENCE</p>
        <p className="hero-text2">OF TIMELESS LUXURY</p>
      </div>
      {/* <div className="banner">
        <img src={bannerImage} alt="banner"/>
      </div> */}
      <a href={Globalwhatsapp} className='whatsap_logo' target='_blank'>
        <img src={whatsap}/> <span>Join Community</span>
      </a>
    </div>
    
    </>
  );
};

export default Hero;

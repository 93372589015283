import React, { useEffect, useState } from "react";
// import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import { TiArrowDown } from "react-icons/ti";
import ResponsivePagination from 'react-responsive-pagination';
import { backend_url } from "../../Path";
import 'react-responsive-pagination/themes/classic.css';
import { formatIndianCurrency } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid,Autocomplete, TextField} from "@mui/material";
import { FiSearch } from "react-icons/fi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const AllOrders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [drop1, setDrop1] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ordersData, setOrdersData] = useState([]);
  const [orderToChangeStatus, setOrderToChangeStatus] = useState(null);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [Status, setStatus] = useState();
  const [status,setstatus]=useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [customerNames, setCustomerNames] = useState([]);
 const [searchDiv,setSearchDiv]=useState(false);
 const [selectedCustomer, setSelectedCustomer] = useState(null);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const formatDate = (value) => {
    const date = new Date(value);

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    }).format(date);

    return formattedDate;
  }
  const handleSelectChange = (event) => {
    const selectedName = event.target.value;
    const customer = customerNames.find(
      (customer) => `${customer.first_name} ${customer.last_name}` === selectedName
    );
    setSelectedCustomer(customer);
    fetchOrdersData({
      customerName: customer ? customer.first_name : null,
      orderStatus: selectedStatus,
    });
  };
  const filter_status = (value) => {
    var status = '';
    if(value == 0){
        status = 'Pending';
    }
    else if(value == 1){
        status = 'Processing';
    }
    else if(value == 2){
        status = 'Failed';
    }
    else if(value == 3){
        status = 'Cancelled';
    }
    else if(value == 4){
        status = 'Completed';
    }
    return status;
  }
  const get_customer = (value) => {
    value = JSON.parse(value);
    value = value.first_name + ' '+ value.last_name;
    return value;
  }
  // const fetchOrdersData = async () => {
  //   setLoading(true);
  //   const response = await fetch(
  //     `${backend_url}orders/fetch_orders.php?page=${currentPage}&limit=15`
  //   );
  //   const data = await response.json();
  //   setLoading(false);

  //   if (data.status === "success") {
  //       setOrdersData(data.orders);
   
  //       const customerDetails = data.orders
  //       .map(order => {
  //         try {
  //           const shippingArray = JSON.parse(order.shipping_array);
  //           return shippingArray && shippingArray.first_name && shippingArray.last_name
  //             ? { id: order.id, first_name: shippingArray.first_name, last_name: shippingArray.last_name }
  //             : null;
  //         } catch {
  //           console.error(`Error parsing shipping_array for order ID: ${order.id}`);
  //           return null;
  //         }
  //       })
  //       .filter(Boolean); // Remove null entries
  //       setCustomerNames(customerDetails); // Store c
  //     console.log(customerDetails); // Log the result to verify
  
  //       setTotalPages(data.total_pages);
  //   } else {
  //     console.log("Failed to fetch orders.");
  //   }
  // };
 
  const handleChange = (event) => {
    const statusValue = event.target.value;
    setSelectedStatus(statusValue); // Update selected status state
    fetchOrdersData({ customerName: selectedCustomer ? selectedCustomer.first_name : null, orderStatus: statusValue });
  };
  const fetchOrdersData = async (filterParams = {}) => {
    setLoading(true);
    
    // Prepare filter parameters
    const { customerName, orderStatus } = filterParams;

    // Validate filter parameters
    if (customerName && typeof customerName !== 'string') {
        console.warn('customerName must be a string');
        setLoading(false);
        return; // Exit on invalid input
    }

    if (orderStatus && typeof orderStatus !== 'string') {
        console.warn('orderStatus must be a string');
        setLoading(false);
        return; // Exit on invalid input
    }

    // Initialize base URL
    let url = `${backend_url}orders/fetch_orders.php?page=${currentPage}&limit=15`;

    // Create an array to hold query parameters
    const params = [];
    if (customerName) {
        params.push(`first_name=${encodeURIComponent(customerName)}`);
    }
    if (orderStatus) {
        params.push(`order_status=${encodeURIComponent(orderStatus)}`);
    }

    // Append any additional parameters to the base URL
    if (params.length > 0) {
        url += '&' + params.join('&');
    }

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === "success") {
            setOrdersData(data.orders);

            const customerDetailsMap = new Map();

            const customerDetails = data.orders
                .map(order => {
                    try {
                        const shippingArray = JSON.parse(order.shipping_array);
                        if (shippingArray && shippingArray.first_name && shippingArray.last_name) {
                            const key = `${shippingArray.first_name} ${shippingArray.last_name}`;
                            // Check if the ID and the combined names already exist in the map
                            if (!customerDetailsMap.has(key) || customerDetailsMap.get(key) !== order.id) {
                                customerDetailsMap.set(key, order.id);
                                return { id: order.id, first_name: shippingArray.first_name, last_name: shippingArray.last_name };
                            }
                        }
                        return null;
                    } catch {
                        console.error(`Error parsing shipping_array for order ID: ${order.id}`);
                        return null;
                    }
                })
                .filter(Boolean);

            // Transform the map to an array of unique customer details
            const uniqueCustomerDetails = Array.from(customerDetailsMap.keys()).map(key => {
                const [first_name, last_name] = key.split(' ');
                return {
                    id: customerDetailsMap.get(key),
                    first_name,
                    last_name
                };
            });

            setCustomerNames(uniqueCustomerDetails); // Store unique customer details in state
            setTotalPages(data.total_pages);
        } else {
            console.log("Failed to fetch orders.");
        }
    } catch (error) {
        console.error('Fetch error:', error);
    } finally {
        setLoading(false); // Ensure loading state is reset
    }
};
 
  const openOverlay = (val) => {
    setDrop1(val);
    setDropdown(!dropdown);
  }
  const openStatusModal = (val,status) => {
    setOrderToChangeStatus(val);
    setCurrentStatus(status);
    setStatusModalOpen(true);
  };
  const handleStatusUpdate = () => {
    if (orderToChangeStatus !== null) {
      updateOrderStatus(orderToChangeStatus, selectedStatus);
    }
  };
  const updateOrderStatus = async (id, status) => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);
    const response = await fetch(`${backend_url}orders/update_status.php`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.json();
    setSubmitLoading(false);
    if (result.status === "success") {
      toast.success("Order status updated successfully");
      await fetchOrdersData(); // Refresh the order list
      setStatusModalOpen(false);
    } else {
      toast.error("Failed to update order status: " + result.message);
    }
  };
  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }else{
      fetchOrdersData();
    }
  },[]);
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{paddingTop: '20px'}}>Orders List

        <FiSearch className="search_icon" onClick={()=>{setSearchDiv(!searchDiv)}} style={{cursor:'pointer'}}  />
        </h3>
        
     {searchDiv &&

<Grid container spacing={1} className="search_div " alignItems="center">
  {/* <Grid item xs={12} md={6} sm={6}>
   
    <Autocomplete
    
    className="auto_complete"
      options={customerNames}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      onChange={(event, newValue) => {
        setSelectedCustomer(newValue); // Update selected customer state
        fetchOrdersData({ customerName: newValue ? newValue.first_name : null, orderStatus: selectedStatus });
      }}
      renderInput={(params) => (
        <TextField {...params} label="All Users" variant="outlined" />
      )}
      popupIcon={<KeyboardArrowDownIcon />} // Set the custom icon here
      sx={{
        '& .MuiOutlinedInput-root': {
          padding:'0px',
          borderRadius:'0px',
          outline:'1px solid grey',
          '& fieldset': {
           
            borderColor: 'black', // Change this to your desired border color
          },
          '&:hover fieldset': {
            outline:'none',
         
            border:'1px solid black',
            borderColor: 'black', // Change this to your desired hover border color
          },
          '&.Mui-focused fieldset': {
         
          outline:'none',
            
            borderColor: 'black', // Change this to your desired focused border color
          },
        },
      '& .Mui-focused':{
        color:'black',
        outline:'none',

      }
      }}
    />
    
    <select></select>
  </Grid> */}
 <Grid item xs={12} md={6} sm={6}>
      <select
        value={selectedCustomer ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}` : ''}
        onChange={handleSelectChange}
       className="p-2 filter_select"
      >
        <option value="">All Users</option>
        {customerNames.map((customer) => (
          <option
            key={customer.first_name + customer.last_name}
            value={`${customer.first_name} ${customer.last_name}`}
          >
            {customer.first_name} {customer.last_name}
          </option>
        ))}
      </select>
    </Grid>
  <Grid item xs={12} md={6} sm={6}>
    <select
    
      className="p-2 filter_select"
      onChange={(e) => {
        const statusValue = e.target.value;
        setSelectedStatus(statusValue); // Update selected status state
        fetchOrdersData({ customerName: selectedCustomer ? selectedCustomer.first_name : null, orderStatus: statusValue });
      }}
    >
      <option value="">Status</option>
      <option value={0}>Pending</option>
      <option value={1}>Processing</option>
      <option value={2}>Failed</option>
      <option value={3}>Cancelled</option>
      <option value={4}>Completed</option>
    </select>
  </Grid>
</Grid>
    }
     
        <div className="w-full">
          <table className="admin-tables extrapaddingtable">
            <thead>
              <tr>
                <th>Order ID#</th>
                <th>Order value</th>
                <th>Ordered on</th>
                <th>Ordered by</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="6" style={{ textAlign: "center",height:'70vh' }}><CircularProgress color="inherit" className="admin-table-loader"  /></td>
                </tr>
              </tbody>
            ) : (
            <tbody>
              {ordersData && ordersData.length > 0 && 
                ordersData.map((data, index) => (
                  <tr key={index}>
                    <td>#{data.id}</td>
                    <td>{formatIndianCurrency(data.order_value)}</td>
                    <td>{formatDate(data.ordered_on)}</td>
                    <td>{get_customer(data.shipping_array)}</td>
                    <td>{filter_status(data.order_status)}</td>
                    <td className="action-btns">
                      <span onClick={()=>{openOverlay(data.id)}}>
                        <b>Action </b><TiArrowDown />
                        {
                        dropdown && drop1 && (drop1 === data.id) &&
                        (<ul>
                          <li onClick={() => openStatusModal(data.id,data.order_status)}>Order Status</li>
                          <li><a target='_blank' href={'/order-details/'+data.id}>Order detail</a></li>
                        </ul>)}
                      </span>
                    </td>
                  </tr>
                ))
              }
              {ordersData && ordersData.length === 0 && 
                <tr>
                  <td colSpan={6} className="text-center">No records found!!</td>
                </tr>
              }
            </tbody>
            )}
          </table>          
        </div>
        {ordersData && ordersData.length > 0 && totalPages-currentPage !=0 &&
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        }
        
        {/* Status Change Modal */}
        {statusModalOpen && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setStatusModalOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Change Order Status
              </h3>
              <div className="w-full flex items-center justify-center" style={{gap:"20px",display:'flex'}}>
                <select style={{padding:'8PX 16PX'}} className="w-full p-2 border " value={currentStatus} onChange={(e)=>  setSelectedStatus(Number(e.target.value))}>
                  <option value={0}>Pending</option>
                  <option value={1}>Processing</option>
                  <option value={2}>Failed</option>
                  <option value={3}>Cancelled</option>
                  <option value={4}>Completed</option>
                </select>
                {submitLoading ? (
                  <div className="text-right mt-1">
                    <CircularProgress />
                  </div>
                ) : (
                  <button style={{ background:'#111', color:'#fff',padding:'8px 16px',fontWeight:'600',fontSize:'16px',border:'0px'}} onClick={handleStatusUpdate}>Update </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllOrders;

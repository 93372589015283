import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import './style.css';
import { FiInstagram, FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import safety from '../../Assets/safety.png';
import cart from '../../Assets/cart.png';
import transport from '../../Assets/transport.png';
import protection from '../../Assets/protection.png';
import { backend_url } from '../../Path';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [instagramUrl, setInstagramUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(`${backend_url}general_settings/generalsettings.php`);
      const result = await response.json();
      if (result.status === 'success') {
        const data = result.data[0];
        setInstagramUrl(data.instagram_url);
        setWhatsappUrl(data.whatsapp_url);
        setYoutubeUrl(data.youtube_url);
      } else {
        console.error('Failed to fetch URLs');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleValidation = () => {
    const { name, email, phone, subject, message } = formData;
    let isValid = true;

    if (!name.trim()) {
      toast.error('Name is required.');
      isValid = false;
    }
    if (!email.trim()) {
      toast.error('Email is required.');
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error('Please enter a valid email address.');
      isValid = false;
    }

    else if (!phone.trim()) {
      toast.error('Phone number is required.');
      isValid = false;
    } else if (phone.length !== 10) {
      toast.error('Phone number must be exactly 10 digits.');
      isValid = false;
    }
    else if (!subject.trim()) {
      toast.error('Subject is required.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${backend_url}contact-us/contact-us.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);

      if (result.status === 'success') {
        toast.success('Your query has been submitted.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      } else {
        toast.error(result.message || 'Failed to send the message.');
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred while submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="contact-container">
        <Box className="contact-us-page">
          <Grid container>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} className='order2'>
              <div className='contact-form-div'>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Please enter your name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email-Id</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Please enter your email-id"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      onInput={(e) => {
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}
                      placeholder="Please enter your phone number"
                      maxLength={10}
                    />
                  </div>

                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Please enter your subject"
                    />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Type your message"
                    />
                  </div>
                  <div className="form-group contact-btn-div">
                    <button type="submit" className='commonbtnanimation' disabled={isSubmitting}>
                      {isSubmitting ? <CircularProgress size={24} /> : 'Send'}
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
              <img className="contact-us-img" src="https://media.istockphoto.com/id/458066987/photo/rolex-deepsea-wristwatch.jpg?s=612x612&w=0&k=20&c=T3frhDuFQw9YvmuPd0GeK_Ka5NlEFlCmqtWrJtI7beA=" alt="contact-us" />
              <div className='contact-icons-div'>
                <div className='contact-icons-inner-div'>
                  <div className='contact-icon'>
                    <h6>Quick Contact</h6>
                    <p>info@watch.com</p>
                    <span><MdOutlineMail /></span>
                  </div>
                  <div className='contact-icon'>
                    <h6>Phone Number</h6>
                    <p>+91 9898989898</p>
                    <span><FiPhone /></span>
                  </div>
                </div>
                <h4 className="follow-text">FOLLOW US</h4>
                <div className='contact-social-icons'>
  <span>
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <FaWhatsapp /> WhatsApp
    </a>
  </span>
  <span>
    <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
      <FiInstagram /> Instagram
    </a>
  </span>
  <span>
    <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
      <CiYoutube /> Youtube
    </a>
  </span>
</div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <section className='sectionop promises_section'>
        <Box sx={{ width: '100%', alignItems: 'center' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={safety} alt='safety' height={25} width={25} />
                <h6>Authenticity You Can Trust</h6>
                <p>Every timepiece is meticulously verified, ensuring you receive 100% genuine luxury watches backed by our trusted reputation. </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={protection} alt='protection' height={25} width={25} />
                <h6>Complete Transparency</h6>
                <p>We provide clear and honest details on product quality, pricing, and every step of the process—no hidden fees,  no surprises. </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={cart} alt='cart' height={25} width={25} />
                <h6>Facilitated Secure Transactions</h6>
                <p>Our seamless, protected payment system ensures a fair and reliable trade, creating a win-win experience for both buyers and sellers. </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={transport} alt='transport' height={25} width={25} />
                <h6>Worldwide Shipping</h6>
                <p>We deliver luxury to your doorstep, no matter where you are. Global shipping with delivery times tailored to your location. </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
}

export default ContactUs;
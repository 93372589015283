import React,{useEffect} from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
// import order_success from "../../src/Assets/newImages/order_success.png";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Success />
    </div>
  );
};

const Success = () => {
  return (
    <div className='parent_div cart_page whitenav'>
      {/* <div className="sleek_banner">
        <h1>404 - Page Not Found. </h1>
      </div> */}
      <Header activeHeading={1} />
      <section className='cartsection'>
        <div className='my-5' style={{marginBottom:'80px'}}>
          {/* <img className='mx-auto' alt='emptycart_image' style={{maxWidth:'150px'}} src={order_success}/> */}
          <h3 className='text-center my-5' style={{fontSize: '40px',fontWeight: 500,color: '#2c2c2c'}}>404 - Page Not Found.</h3>
        </div>        
      </section>
      <Footer />
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
// import viewed4 from "../../Assets/newImages/viewed2.png";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../redux/actions/cart";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from "react-toastify";
import {formatIndianCurrency} from '../../helpers';
import { backend_url } from "../../Path";
import CircularProgress from "@mui/material/CircularProgress";
import { RxCross1 } from "react-icons/rx";
import { LuRefreshCw } from "react-icons/lu";
import './style.css';
import placeholder from "../../Assets/placeholder.svg"

function Cart() {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [isSubmitting, setisSubmitting] = useState(0);

  const removeCartData = async()=>{
    console.log(cartData);
    setisSubmitting(1);
    const formData = new FormData();
    formData.append("ref_no", cartData.refNo);
    try {
      const response = await fetch(`${backend_url}delete_cart_item.php`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      setisSubmitting(0);
      setStatusModalOpen(false);
      if (result.success) {
        dispatch(removeFromCart(cartData));
        toast.success("Item removed from cart successfully!");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Some problem occurred");
    }
  }

  const removeFromCartHandler = async(data) => {
    // setRefNo(data.reference_number);
    setCartData(data);
    setStatusModalOpen(true);
  };
  
  // const quantityChangeHandler = (data) => {
  //   dispatch(addTocart(data));
  // };
  return(
    <div className='outer_cart_div cartpage'>
      <div className='row headerdiv'>
        <div className='leftside'>
          <span className='number'>1</span>
          <span>Your shopping cart</span>
        </div>
        {/* <div className='rightside'>
          <span>Continue Shopping</span>
        </div> */}
      </div>

      <div className='detail_cart'>
        {cart && cart.length === 0 ? (
          <>
            <div>
              <h3>No items in cart</h3>
              {/* <img src={'https://www.adasglobal.com/img/empty-cart.png'}/> */}
            </div>
          </>
          ) : (
            cart.map((data, index) => (
              <>
                <div className='row' style={{position:'relative'}} key={index}>
                  <CancelOutlinedIcon style={{position:'absolute',right:0,cursor:'pointer'}} onClick={() => removeFromCartHandler(data)}/>
                  <div className='leftside'>
                    <Grid className='align-center' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                      <Grid item lg={5} md={4} sm={5} xs={3}>
                        {/* <img src={require(`../../Assets/newImages/${data.images[0].url}`)} style={{maxWidth:'114px', margin:'auto'}}/> */}
                        <img src={data.images[0].url} onError={(e) => e.target.src = placeholder}  style={{maxWidth:'114px', margin:'auto'}}/>
                      </Grid>
                      <Grid item lg={7} md={8} sm={7} xs={9} className='item_detail'>
                        <h4>{data.brand}</h4>
                        <p>{data.name}</p>
                        <span>{data.reference_number}</span>
                        <h3 className='mobile_only'>{formatIndianCurrency(data.price)}</h3>
                      </Grid>
                    </Grid>
                  </div>
                  <div className='rightside'>
                    <h3>{formatIndianCurrency(data.price)}</h3>
                  </div>
                </div>
              </>
          )))
        }
      </div>

      <div className='row headerdiv listingheaders'>
        <div className='leftside'>
          <span className='number'>2</span>
          <span>Shipping Address</span>
        </div>
      </div>

      <div className='row headerdiv listingheaders'>
        <div className='leftside'>
          <span className='number'>3</span>
          <span>Payment Method</span>
        </div>
      </div>

      {/* Status Change Modal */}
        {statusModalOpen && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setStatusModalOpen(false)} />
              </div>
              <h3 className="text-[22px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to remove this item ?
              </h3>
              <div className="w-full flex items-center justify-center">
                <button
                  className={`text-white text-[16px] !h-[42px] mr-4`}
                  onClick={() => setStatusModalOpen(false)}
                  style={{borderRadius:'0px',background:'black',padding:'0px 10px 0px 10px'}}
                >
                  Cancel
                </button>
                <button
                  className={`text-white text-[16px] !h-[42px] ml-4`}
                  onClick={removeCartData}
                  style={{borderRadius:'0px',background:'black',padding:'0px 10px 0px 10px'}}
                >  {isSubmitting === 1 ? (
                  <CircularProgress color="inherit" />
                ) : (
                  " Confirm"
                )}
                  
                </button>
              </div>
            </div>
          </div>
        )}



    </div>
    // <div className='parent_div cart_page'>
    //   <Header activeHeading={1} />
    //   <section className='cartsection'>
    //     <Grid container className='outerr' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 8 }}>
    //       <Grid item md={8} sm={8} xs={12}>
    //         <div className='outer_cart_div'>
    //           <div className='row headerdiv'>
    //             <div className='leftside'>
    //               <span className='number'>1</span>
    //               <span>Your shopping cart</span>
    //             </div>
    //             <div className='rightside'>
    //               <span>Continue Shopping</span>
    //             </div>
    //           </div>

    //           <div className='detail_cart'>
    //             <div className='row'>
    //               <div className='leftside'>
    //                 <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
    //                   <Grid item md={3} sm={6} xs={12}>
    //                     <img src={viewed4} style={{maxWidth:'114px', margin:'auto'}}/>
    //                   </Grid>
    //                   <Grid item md={9} sm={6} xs={12} className='item_detail'>
    //                     <h4>ROLEX</h4>
    //                     <p>ROLEX Oyster Perpetual Sea-Dweller 43 mm Ceramic Bezel Stainless Steel</p>
    //                     <span>210.22.42.20.01.004</span>
    //                   </Grid>
    //                 </Grid>
    //               </div>
    //               <div className='rightside'>
    //                 <h3>₹ 6,38,542</h3>
    //               </div>
    //             </div>
    //             {/* <div>
    //               <button className='checkoutbtn'>Proceed to Checkout</button>
    //             </div> */}
    //           </div>

    //           <div className='row headerdiv listingheaders'>
    //             <div className='leftside'>
    //               <span className='number'>2</span>
    //               <span>Shipping Address</span>
    //             </div>
    //           </div>

    //           <div className='row headerdiv listingheaders'>
    //             <div className='leftside'>
    //               <span className='number'>3</span>
    //               <span>Payment Method</span>
    //             </div>
    //           </div>
    //         </div>
    //       </Grid>
    //       <Grid item md={4} sm={4} xs={12}>
    //         <div className='cart_summary_div'>
    //           <h4><span style={{display: 'inline-block',verticalAlign: 'bottom',marginRight:'8px'}}><img src={clipboard} style={{width: '27px'}}/></span>Order Summary</h4>
    //           <div className='pricings'>
    //             <div className='row'>
    //               <h5>Order Value</h5>
    //               <span>₹ 6,38,542</span>
    //             </div>
    //             <div className='row'>
    //               <h5>Shipping</h5>
    //               <span>Free</span>
    //             </div>
    //             <div className='row' style={{borderTop: '1px solid',paddingTop: '8px',marginTop: '8px',marginBottom: 0}}>
    //               <h5 style={{fontWeight:600}}>Grand Total</h5>
    //               <span style={{fontWeight:600}}>₹ 6,38,542</span>
    //             </div>
    //           </div>
    //           <div>
    //             <button className='checkoutbtn'>Proceed to Checkout</button>
    //           </div>
    //         </div>
    //       </Grid>
    //     </Grid>
    //   </section>
    //   <div className='statss'>
    //     <OurPromises/>
    //   </div>
    //   <Footer />
    // </div>
  );
}

export default Cart;
import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { formatIndianCurrency } from "../../helpers";
import "./style.css";
import defaultImg from "../../Assets/dummy-user.png";
// import verifieduser from "../../Assets/verified_user.png";
// import { categoriesData, productData } from "../../static/data";
// import {
//   AiOutlineHeart,
//   AiOutlineSearch,
//   AiOutlineShoppingCart,
// } from "react-icons/ai";
// import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FaBars } from "react-icons/fa6";
// import MenuItem from "@mui/material/MenuItem";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { BiMenuAltLeft } from "react-icons/bi";
// import { CgProfile, CgLogOut } from "react-icons/cg";
// import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { clearUserId, setLoggedIn } from "../../redux/actions/userData";
// import Cart from "../cart/Cart";
// import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { FiSearch } from "react-icons/fi";
import { LuUser2 } from "react-icons/lu";
// import { FaUserCircle } from "react-icons/fa";
import light_logo from "../../Assets/newImages/light_logo.png";
import dark_logo from "../../Assets/newImages/dark_logo.png";
// import mob_logo from "../../Assets/newImages/mob_logo.png";
// import search from "../../Assets/Search.png";
// import profile from "../../Assets/Profile.png";
// import heart from "../../Assets/Heart.png";
// import notification from "../../Assets/Notification.png";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { IoCaretDownOutline } from "react-icons/io5";
import { FaCaretRight } from "react-icons/fa";
// import { Person4 } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { signOut, getCurrentUser } from "aws-amplify/auth";
import { backend_url } from "../../Path";
const Header = ({ activeHeading
 }) => {
  const navigate = useNavigate();
  const id = useSelector((state) => state.userData.userId);

  const loggedIn = useSelector((state) => state.userData.loggedIn);
  const dispatch = useDispatch();
  // const { isAuthenticated, user } = useSelector((state) => state.user);
  // const { isSeller } = useSelector((state) => state.seller);
  // const { wishlist } = useSelector((state) => state.wishlist);
  // const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  // const [dropDown, setDropDown] = useState(false);
  // const [openCart, setOpenCart] = useState(false);
  // const [openWishlist, setOpenWishlist] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  // const [userId, setUserId] = useState("");
  const [menuColor, setMenuColor] = useState("dark-nav");
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [profileImg, setProfileImg] = useState();
  const [showProfileImg, setShowProfileImg] = useState("visible");
  const [imageState, setImageState] = useState(0); // Default to 0 to show the icon

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    dispatch(clearUserId());
    dispatch(setLoggedIn(false));

    // Remove userId and loggedIn flag from sessionStorage
    sessionStorage.removeItem("userId");
    sessionStorage.setItem("loggedIn", false);
    navigate("/");
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `${backend_url}/users/fetch_user_data.php?id=${id}`
      );
      const data = await response.json();
      const user = data.data[0];

      if (data.status === "success") {
        console.log("num", user.phone_num);
        if (user.phone_num === undefined || user.phone_num === null) {
          console.log("empty");
        } else {
          console.log("not empty");
        }

        setProfileImg(user.image || "");

        if (
          user.image == null ||
          user.image === undefined ||
          user.image === ""
        ) {
          setImageState(0);
        } else {
          setImageState(1); // Set state to 1 to show profile image
        }
        setShowProfileImg(profileImg);
        // setShowProfileImg("not visible");
      } else {
        console.log("Failed to fetch products.");
      }
    } catch (err) {
      console.log("An error occurred while fetching products.");
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [id]);
  const handleSearchChange = (e) => {
    const term = e.target.value;
    if (e.key === 'Enter' && term.trim() == "") {
      toast.error('please enter any keyword. ');
    }
    
    setSearchTerm(term);
    if (term.trim() !== "") {
      // Fetch filtered products from the backend
      fetch(`${backend_url}products/header_search.php?q=${encodeURIComponent(term)}`)
        .then((response) => response.json())
        .then((data) => {
          // Check if data is an array before setting it to state
          if (Array.isArray(data)) {
            setSearchData(data); // Valid data array
            setIsDropdownVisible(true); // Show dropdown when data is received
          } else {
            console.error("Unexpected data format:", data);
            setSearchData([]); // Set empty array if data is not valid
            setIsDropdownVisible(false); // Hide dropdown if no valid data
          }
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
          setIsDropdownVisible(false); // Hide dropdown on error
        });
    } else {
      // If the search term is empty, reset the search data or show all products
      setSearchData(allProducts);
      setIsDropdownVisible(false); // Hide dropdown when search term is cleared
    }
  };
  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Hide the dropdown
    }
  };
  useEffect(() => {
    // Add event listener for detecting clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // async function handleSignOut() {
  //   try {
  //     await signOut();
  //     localStorage.clear();
  //     navigate("/login");
  //   } catch (error) {
  //     console.log("error signing out: ", error);
  //   }
  // }

  // async function currentAuthenticatedUser() {
  //   try {
  //     const { username, userId, signInDetails } = await getCurrentUser();
  //     if (userId != "") {
  //       setUserId(userId);
  //     }
  //     console.log(`The username: ${username}`);
  //     console.log(`The userId: ${userId}`);
  //     console.log(`The signInDetails: ${signInDetails}`);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  useEffect(() => {
    const url = window.location.pathname;
    if (
      url.includes("/productdetail") ||
      url === "/products" ||
      url === "/index-detail" ||
      url === "/blogs" ||
      url === "/contact-us" ||
      url === "/sign-up" ||
      url === "/login" ||
      url === "/forgot-pwd" ||
      url === "/reset-pwd" ||
      url.includes("/blog-details") ||
      url === "/faq" ||
      url === "/privacy-policy" ||
      url === "/terms-and-conditions" ||
      url === "/cart" ||
      url === "/user-profile" ||
      url === "/user-identity" ||
      url === "/my-orders" ||
      url.includes("/order-details") ||
      url === "/checkout/cart" ||
      url === "/checkout/shipping" ||
      url === "/checkout/payment" ||
      url === "/payment-settings" ||
      url === "/product-listing" ||
      url === "/payment-settings" ||
      url.includes("/product-listing") ||
      url === "/order/success"
    ) {
      setMenuColor("light-nav");
    } else {
      setMenuColor("dark-nav");
    }
    // currentAuthenticatedUser();
  }, []);

  return (
    <>
      {/* <div className={``}>
        <div
          className="hidden 800px:h-[20px] 800px:py-[20px] 800px:flex d-row items-center justify-end"
          style={{ background: "#191B1A" }}
        >
          <p className="pr-20 text-[12px] font-[Sharp_Grotesk] font-[400] text-[#F0F0F0] text-center">
            Experience The Luxury of Time
          </p>
        </div>
      </div> */}
      <div
        className={
          `${
            active === true
              ? "shadow-sm fixed top-0 left-0 z-10 " + menuColor
              : null
          } transition hidden 800px:flex items-center justify-between w-full h-[70px] ` +
          menuColor
        }
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          <div>
            <Link to="/" className="header-logo">
              <img
                src={menuColor === "light-nav" ? dark_logo : light_logo}
                alt=""
              />
            </Link>
          </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex} nav-menus`}>
            <Navbar active={activeHeading} />
          </div>

          <div className="flex header-search-option">
          <input
        type="search"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        onClick={handleSearchChange}
        onKeyDown={handleSearchChange}

      />
      <FiSearch />
      {isDropdownVisible && searchData && (
        <div ref={dropdownRef} className="w-full left-0 p-3">
          <div
            className="sell-index-form-dropdown"
            style={{ maxHeight: '300px', overflowY: 'scroll', zIndex: 1 }}
          >
            {searchData.length > 0 ? (
              searchData.map((item) => (
                <div key={item.id} className="product">
                  <ul>
                    <li style={{ borderBottom: '1px solid #5F5F5F' }}>
                    <Link to={`/productdetail/#${item.id}`}
                       onClick={()=>{
                        if(window.location.hash.includes(item.id)){
                       setSearchData('');
                       setSearchTerm('');
                        }}}
                      >
                        <h5>{item.brand}</h5>
                        <h6 style={{ fontSize: '12px' }}>
                          {item.name} - ₹{item.price.toLocaleString('en-IN')}
                        </h6>
                      </Link>
                    </li>
                  </ul>
            {/* <input type="text" placeholder="Search"         value={searchTerm}
                  onChange={handleSearchChange}/>
            <FiSearch />
            {searchData && (
                  <div className="w-full left-0 p-3">
                  <div className="sell-index-form-dropdown" style={{maxHeight:'300px',overflowY:'scroll',zIndex:1}}>
        {searchData && searchData.length > 0 ? (
  searchData.map((item, index) => (
    <div key={item.id} className="product"
    
    > <ul>
      <li style={{borderBottom:'1px solid #5f5f5f'}}><Link to={`/productdetail/#${item.id}`}>
         <h5>{item.brand} </h5>
         <h6 style={{fontSize:'12px'}}>{item.name} - {formatIndianCurrency(item.price)}</h6>
         </Link>
         </li>
      </ul> */}
      {/* <p><strong>Price:</strong> ₹{item.price}</p> */}
      {/* {item.revenue && <p><strong>Revenue:</strong> ₹{item.revenue}</p>} */}
      {/* Add more details as needed */}
    </div>
  ))
) : (
  <p>No results found</p>
)}
</div>
                  </div>
                )}
          </div>
     
          <div>
            {loggedIn && (
              <div style={{ position: "relative", display: "inline-block" }}>
                {/* User icon and dropdown toggle */}
                <div
                  onClick={toggleDropdown}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    color: menuColor === "light-nav" ? "black" : "white",
                  }}
                > 
                  <div className="Profile">
                    <img
                      src={
                        profileImg
                          ? `${backend_url}users/profile/${profileImg}`
                          : "../../Assets/address.png"
                      }
                      alt="Profile"
                      onError={(e) => (e.target.src = defaultImg)}
                    />
                  </div>
                 {/* {!isOpen ?
                   <FaCaretRight className=" mt-5 mx-0" style={{ fontSize: "24px" }} />:
                  <IoCaretDownOutline className=" mt-5" style={{ fontSize: "22px" }} />
                 } */}
                </div>

                {/* Dropdown menu */}
                {isOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      backgroundColor: "white",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",

                      marginTop: "10px",
                      minWidth: "150px",

                      zIndex: 1000,
                    }}
                  >
                    <ul
                      className="dropdown_user"
                      style={{
                        listStyle: "none",
                        margin: 0,
                        padding: "10px 0",
                        minWidth: "200px",
                      }}
                    >
                  <Link to="/user-profile">    <li style={{ padding: "8px 16px", cursor: "pointer" }}>
                        Profile
                      </li>
                      </Link>

                      <Link to="/my-orders">
                      <li style={{ padding: "8px 16px", cursor: "pointer" }}>
                        My Orders
                      </li>
                      </Link>

                      <Link to="/payment-settings">

                      <li style={{ padding: "8px 16px", cursor: "pointer" }}>
                        Payment Settings
                      </li> 
                      </Link>

                      <Link to="/checkout/cart">

                      <li style={{ padding: "8px 16px", cursor: "pointer" }}>
                        My Cart
                      </li>
                      </Link>

                      <Link to="/product-listing">

                      <li style={{ padding: "8px 16px", cursor: "pointer" }}>
                        My Products
                      </li>
                      </Link>

                      <li
                        style={{ padding: "8px 16px", cursor: "pointer" }}
                        onClick={handleLogout}
                      >
                        Logout
                      </li>
                    </ul> 
                  </div>
                )}
              </div>
            )}
          </div>

          {!loggedIn && (
            <div className="flex authentication nav-menus">
              <a className="font-[500] login-menus">
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </span>
                /
                <span
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Signup
                </span>
              </a>
            </div>
          )}

          {/* <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[25px]">
                <Link to="/indexpage">
                  <img src={search} alt="" />
                </Link>
              </div>
            </div>
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[25px]">
                <Link to="/#">
                  <img src={heart} alt="" />
                </Link>
              </div>
            </div>
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[25px]">
                {userId!='' ? (
                  <a style={{ cursor: "pointer" }} onClick={handleSignOut}>
                    <CgLogOut size={30} color="rgb(255 255 255 / 83%)" />
                  </a>
                ) : (
                  <Link to="/login">
                    <img src={profile} alt="" />
                  </Link>
                )}
              </div>
            </div>
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[25px]">
                <Link to="/productdetail">
                  <img src={notification} alt="" />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "fixed top-0 left-0 z-10" : null
        } w-full h-[60px] z-50 top-0 left-0 800px:hidden mobile_nav_div`}
        style={{ position: "fixed" }}
      >
        <Grid className="w-full new-mobile-header" container>
          <Grid sm={4} xs={4} className="m-auto" item>
            <div>
              <FaBars
                size={40}
                style={{ width: "16px" }}
                fill="white"
                onClick={() => setOpen(true)}
              />
            </div>
          </Grid>
          <Grid sm={4} xs={4} item>
            <Link to="/">
              <img
                style={{ height: "26px" }}
                src={light_logo}
                alt="mobile logo large_logo"
                className="cursor-pointer hearder-logo m-auto dark_pages_only"
              />
              <img
                style={{ height: "26px", display: "none" }}
                src={dark_logo}
                alt="mobile logo large_logo"
                className="cursor-pointer hearder-logo m-auto light_pages_only"
              />
              {/* <img src={mob_logo} alt="mobile logo small_logo" className="cursor-pointer hearder-logo m-auto"/> */}
            </Link>
          </Grid>
          <Grid sm={4} xs={4} className="new-mobile-header-bars" item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "6px",
              }}
            >
              <FiSearch
                className="searchiconsvg"
                style={{ height: "17px", width: "17px"}}
                color="white"
                onClick={() => setOpen1(true)}
              />
              {!loggedIn && (
              <LuUser2
                color="white"
                onClick={() => {
                  navigate("/login");
                }}
                style={{ marginLeft: "7px" }}
              />)}
              {/* <Link to="/login" className="text-white">Login/</Link>
              <Link to="/sign-up" className="text-white">Sign up</Link> */}
            </div>
            {/* 
            <div
              style={{ position: 'relative' }}
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} className="relative" />
              <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart.length}
              </span>
              
              {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

              {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
            </div> */}
          </Grid>
        </Grid>

        {open1 && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[100%] bg-[#fff] h-screen top-0 right-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                {/* <Link to="/" style={{ margin: 'auto', paddingTop: '16px' }}>
                  <img
                    src={dark_logo}
                    alt=""
                    className="cursor-pointer hearder-logo"
                  />
                </Link> */}
                <RxCross1
                  size={30}
                  className="ml-1 mt-1"
                  onClick={() => setOpen1(false)}
                  style={{
                    marginTop: 0,
                    height: "18px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
              </div>

              <div className="my-8 w-[88%] m-auto h-[40px relative] mobile-header-search">
                <input
                  type="search"
                  placeholder="Search by Brand and Model Number..."
                  className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md mobile-search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <FiSearch size={40} className="" />
                {searchData && (
                  <div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
                  <div className="sell-index-form-dropdown" style={{maxHeight:'300px',overflowY:'scroll'}}>
        {searchData && searchData.length > 0 ? (
  searchData.map((item, index) => (
    <div key={item.id} className="product"
    
    > <ul>
      <li style={{borderBottom:'1px solid #5f5f5f'}}>
      <Link to={`/productdetail/#${item.id}`} onClick={()=>{
                        if(window.location.hash.includes(item.id)){
                       setOpen1(false);
                        }}
                        }>        
         <h5>{item.brand} </h5>
         <h6 style={{fontSize:'12px'}}>{item.name} - {formatIndianCurrency(item.price)}</h6>
         </Link>
         </li>
      </ul>
      {/* <p><strong>Price:</strong> ₹{item.price}</p> */}
      {/* {item.revenue && <p><strong>Revenue:</strong> ₹{item.revenue}</p>} */}
      {/* Add more details as needed */}
    </div>
  ))
) : (
  <p>No results found</p>
)}
</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* header sidebar */}
        {open && (
          <div
            className="innernavdiv"
            style={{
              background: "#f3f3f3",
              padding: "13px 8px",
              borderTop: "1px solid #00000033",
              borderBottom: "1px solid #00000033",
              marginTop: "9px",
              paddingBottom: "17px",
              background: "black",
              color: "white",
              borderTop: "1px solid #ffffff33",
              
            }}
          >
            <div className="">
              <div
                className="w-full justify-end"
                style={{ position: "relative" }}
              >
                {/* <Link to="/" style={{ margin: 'auto', paddingTop: '16px' }}>
                  <img
                    src={dark_logo}
                    alt=""
                    className="cursor-pointer hearder-logo"
                  />
                </Link> */}
                <RxCross1
                  size={30}
                  className="ml-1 mt-1"
                  onClick={() => setOpen(false)}
                  style={{
                    marginTop: 0,
                    height: "18px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
              </div>

              <div className="my-8 w-[92%] m-auto h-[40px relative]">
                {/* <input
                  type="search"
                  placeholder="Search Product..."
                  className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
                  value={searchTerm}
                  onChange={handleSearchChange}
                /> */}
                {searchData && (
                  <div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
                    {searchData.map((i) => {
                      const d = i.name;

                      const Product_name = d.replace(/\s+/g, "-");
                      return (
                        <Link to={`/product/${Product_name}`}>
                          <div className="flex items-center">
                            <img
                              src={i.image_Url[0]?.url}
                              alt=""
                              className="w-[50px] mr-2"
                            />
                            <h5>{i.name}</h5>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <Navbar active={activeHeading} />
              {/* <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">  
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div> */}
              <div className="flex w-full  justify-start">
                {loggedIn ? (
                  <div className="mob-navbar block 800px:flex items-center">
                    <ul className="all-menus ">
                      <li className=" my-3 w-100">
                        <Link to="/user-profile">Profile</Link>
                      </li>
                      <li className=" my-3">
                        <Link to="/my-orders ">My Orders</Link>
                      </li>
                      <li className=" my-3">
                        <Link to="/payment-settings">Payment Settings</Link>
                      </li>
                      <li className=" my-3">
                        <Link to="/checkout/cart">My Cart</Link>
                      </li>
                      <li className=" my-3">
                        <Link to="/product-listing">My Products</Link>
                      </li>
                      <li className="my-3" onClick={handleLogout}>
                        Logout
                      </li>
                    </ul>
                  </div>
                ) : (
                  <>
                    <div className="mob-menuss">
                      <Link to="/login" className="">
                        Login
                      </Link>
                      <Link to="/sign-up" className="">
                        Sign up
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

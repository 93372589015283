import React, { useState,useRef } from 'react';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';
import why1 from "../../Assets/newImages/new_why1.png";
import why2 from "../../Assets/newImages/why2.png";
import why3 from "../../Assets/newImages/why3.png";
import tip1 from "../../Assets/newImages/tip1.png";
import tip2 from "../../Assets/newImages/tip2.png";
import tip3 from "../../Assets/newImages/tip3.png";
import search from "../../Assets/newImages/search.png";
import { backend_url } from "../../Path";
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatIndianCurrency } from "./../../helpers";

function Index() {
  const navigate = useNavigate();
  const search_input = useRef(null);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showDropBox, setShowDropBox] = useState(false);
  const [productName, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [source, setSource] = useState('');
  const [watchCondition, setWatchCondition] = useState('');
  const [deliveryInclusion, setDeliveryInclusion] = useState('');

  const getSearchData = async(e) => {
    e.preventDefault();
    setProducts([]);
    var search_text = e.target.value;
    if(search_text.length  > 3){
      const data = new FormData();
      data.append('searchText', search_text);

      fetch(backend_url+"products/search_products.php", {
        method: "POST",       
        body: data				
      })
      .then(res => res.json())
      .then(response=>{
        if(response.length > 0){
          setShowDropBox(true);
          setProducts(response);
        }else{
          setShowDropBox(false);
          setProducts(response);
        }
      }
      )
      .catch(err => {
        console.log('err',err);
      })
    }
  };

  const setValue = async(value,id) => {
    search_input.current.value  = value;
    search_input.current.id  = id;
    setShowDropBox(false);
  }

  const changeProductSelected = (id,slug) => {
    console.log(id,slug);
    let source;
    if(slug === "" || slug === null){
      source = 'id';
      setSlug(id);
      setSource('id');
    }else{
      source = 'slug';
      setSlug(slug);
      setSource('slug');
    }
  }

  const searchAppraisal = async() => {
    if(search_input.current.value === ""){
      toast.error("Input watch");
    }else if(watchCondition === ""){
      toast.error("Please select the watch's condition.");
    }else if(deliveryInclusion === ""){
      toast.error("Please select the delivery inclusions");
    }else{
      const formDataToSend = new FormData();
      setName(search_input.current.value);
      formDataToSend.append("slug", slug);
      formDataToSend.append("source", source);
      formDataToSend.append("condition", watchCondition);
      formDataToSend.append("deliveryInclusion", deliveryInclusion);

      fetch(`${backend_url}/check_appraisal.php`, {
        method: "POST",
        body: formDataToSend,
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setPrices(data);
          setShowResults(true);
        } else {
          toast.error("Could not find appraisal value");
        }
      })
    }
  }

  return(
    <div className='main_div appraisal_page home_main_div'>
      <div className='upper_area'>
        <Header />
        <div className={showResults ? `hero-section banner appraisal-section-banner resultsbanner` : `hero-section banner appraisal-section-banner`}>
          <div className="hero-heading" >
            <Grid container>
              {showResults === true ? (
                <Grid item xl={12} md={12} sm={12} xs={12} className="banner-floating-section">
                  <div className='banner-floating-div market_value_div'>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h2 className='text-center text-white mb-5 crnt_market_val'>Current Market Value</h2>
                        <h3 className='text-white text-center'>{productName}, {watchCondition}, {deliveryInclusion}</h3>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.min_price)}</h2>
                        <span className='text-white valuu'>Min. Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.average_price)}</h2>
                        <span className='text-white valuu'>Average Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.max_price)}</h2>
                        <span className='text-white valuu'>Max. Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <button className="commonbtnanimation" onClick={()=>navigate('/sell-index')}>Sell Now</button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={11} id="hero-heading">
                    <p className="hero-text1">DISCOVER YOUR </p>
                    <p className="hero-text2">WATCH'S</p>
                    <p className="hero-text2 mobilehide">TRUE VALUE!</p>
                    <p className='text-white descriptiontext'>Instant Evaluation. Maximum Worth.</p>
                  </Grid>
                  
                  <Grid item xl={7} lg={7} md={8} sm={10} xs={11} className="banner-floating-section">
                    <div className='banner-floating-div' >
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <input type="text" placeholder="Reference number, model, brand" ref={search_input} onChange={getSearchData}></input>
                            <img src={search}></img>

                            {showDropBox &&
                            (<div className="sell-index-form-dropdown">
                              <ul>
                                {products && products.length > 0 &&
                                products.map(item => (
                                  <li onClick={()=>{setValue(item.name,item.id)}} key={item.id}>
                                    <Link onClick={()=>changeProductSelected(item.id,item.slug)}>
                                      <h5>{item.name}</h5>
                                      <h6 style={{color:'#7e7e7e'}}>{item.brand}</h6>
                                      <h6>{item.reference_number} - ₹{item.api_price}</h6>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>)}
                          </div>
                        </Grid>  
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <select onChange={(event) => setWatchCondition(event.target.value)} placeholder="Watch Condition">
                              <option value="">Watch Condition</option>
                              <option value="Like new (no signs of wear)">Like new (no signs of wear)</option>
                              <option value="Brand New/Unworn">Brand New/Unworn</option>
                              <option value="Pre-owned">Pre-owned</option>
                            </select>
                          </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <select onChange={(event) => setDeliveryInclusion(event.target.value)} placeholder="Delivery Inclusions">
                              <option value="">Delivery Inclusions</option>
                              <option value="Watch only">Watch only</option>
                              <option value="Watch with original box">Watch with original box</option>
                              <option value="Watch with original papers">Watch with original papers</option>
                              <option value="Watch with original box and papers">Watch with original box and papers</option>
                            </select>
                          </div>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="appraisel-form-group explorebtndiv">
                            <button className="explore-btn commonbtnanimation black_bg_animation" onClick={searchAppraisal}>EXPLORE</button>
                          </div>
                        </Grid>  
                      </Grid>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
      </div>
      {/* <Header activeHeading={1} /> */}
        {/* <section className='appraisel-banner-section'>
          <Grid container className='appraisel-banner-div'>
            <Grid item xl={5} lg={5} md={5} sm={7} xs={12}>
              <h2 className='gotham_book'>Know Your </h2>
              <h3 className='gotham_medium'>Watch's Worth!</h3>
              <p>Get it evaluated immediately and then sell it for the highest possible value.</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={7} lg={7} md={8} sm={10} xs={11} className="banner-floating-section">
              <div className='banner-floating-div'>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <input type="text" placeholder="Reference number, model, brand"></input>
                      <img src={search}></img>
                    </div>
                  </Grid>  
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <select placeholder="Watch Condition">
                        <option>Watch Condition</option>
                        <option>Like new (no signs of wear)</option>
                        <option>Brand New/Unworn</option>
                        <option>Pre-owned</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <select placeholder="Delivery Inclusions">
                        <option>Delivery Inclusions</option>
                        <option>Watch only</option>
                        <option>Watch with original box</option>
                        <option>Watch with original papers</option>
                        <option>Watch with original box and papers</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="appraisel-form-group explorebtndiv">
                      <button className="explore-btn commonbtnanimation black_bg_animation ">Explore</button>
                    </div>
                  </Grid>  
                </Grid>
              </div>
            </Grid>
          </Grid>
        </section> */}

        <section className='why-watch-section section-padding'>
          <h1>No WIN. NO FEE</h1>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={why1} alt="p-images"></img>
                <h3>100% Free Evaluation</h3>
                <p>No fees, no obligations. Simply share your watch’s model and condition to get started.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={why2} alt="p-images"></img>
                <h3>Expert Market Insights</h3>
                <p>We evaluate your watch against 500,000+ global listings and current market trends to provide you with an accurate and competitive value.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div l-border'>
                <img src={why3} alt="p-images"></img>
                <h3>Maximized Value</h3>
                <p>We prioritize authenticity, precision, and trust to ensure your timepiece gets the recognition—and value—it truly deserves.</p>
              </div>
            </Grid>
          </Grid>
        </section>

        <section className='guide-section section-padding'>
          <Grid container className='guide-section-div'>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className="outer-guide-div">
              <h1>HOW IT WORKS<br/>A QUICK 3-STEP APPRAISAL GUIDE</h1>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={6} className="app-btn outer-guide-div">
             <a href="#hero-heading"> <button className='desktop_only commonbtnanimation'>Start Your Appraisal</button></a>
            </Grid>
          </Grid>
          <Grid container className='guide-section-div'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div p-border">
              <div className="guide-div">
                <h6>Identify Your Reference Number</h6>
                <p>Locate it on the dial, case back, watch papers, or lugs.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div p-border">
              <div className="guide-div">
                <h6>Assess Your Watch’s Condition</h6>
                <p>Share whether your watch is unworn, lightly used, or pre-owned.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div">
              <div className="guide-div">
                <h6>Verify Included Items</h6>
                <p>Specify if you have the watch alone, the original box, or both box and papers.</p>
              </div>
            </Grid>
          </Grid>
          <a href="#hero-heading"> <button className='mobile_only'>Start Your Appraisal</button></a>
        </section>

        <section className='appFaq-section'>
          <div className='appFaq-section-div'>
            <Grid container className='appFaq-overlay-div section-padding'>
              <Grid item xl={3} lg={3} md={4} sm={4} xs={12} className="outer-guide-div">
                <h1>FREQUENTLY ASKED QUESTIONS</h1>
              </Grid>
              <Grid item xl={9} lg={9} md={8} sm={8} xs={12} className="outer-guide-div">
                <div className="appFaq-div">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      How is my watch’s value determined?
                    </AccordionSummary>
                    <AccordionDetails>
                    At I.W.C., we determine your watch’s value by comparing it to over 500,000 listings worldwide. We take into account its model, condition, authenticity, and current market trends. Our free appraisal tool provides a quick and reliable estimate, ensuring you receive the most accurate pricing to help you sell your timepiece for the best possible value.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Is the watch appraisal free?
                    </AccordionSummary>
                    <AccordionDetails>
                    Yes, our watch appraisal service is entirely free. Simply provide us with some basic information about your watch, and we’ll provide you with an accurate valuation.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      Do I need to send my watch in for appraisal?
                    </AccordionSummary>
                    <AccordionDetails>
                    No, you don’t need to send your watch to us. The appraisal process can be completed entirely online by providing details such as the watch's reference number, condition, and any accompanying documents.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4-content"
                      id="panel4-header"
                    >
                      What factors influence my watch’s value?
                    </AccordionSummary>
                    <AccordionDetails>
                    Several factors determine the value of your watch, including its condition, the presence of the original box and papers, the complexity of its features, the authenticity of its components, and whether it is a limited edition.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5-content"
                      id="panel5-header"
                    >
                      What if my watch is missing the original box or papers?
                    </AccordionSummary>
                    <AccordionDetails>
                    We can still appraise your watch even if the original box or papers are missing. However, having the original packaging and documentation can enhance its value.
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className='why-watch-section tips_section section-padding'>
          <h1>GUIDE FOR PRIVATE SELLERS</h1>
          <Grid container className='products-last-section'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={tip1} alt="p-images"></img>
                <h3>Capture High-Quality Images</h3>
                <p>Make sure to take high-resolution images from multiple angles to showcase your watch’s features. The more images you provide, the better the chances for your listing to appear to potential buyers.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={tip2} alt="p-images"></img>
                <h3>Set a Fair Price</h3>
                <p>Price your watch realistically to increase the likelihood of a sale. Choose an amount, keeping in mind market trends and the watch's condition.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div l-border'>
                <img src={tip3} alt="p-images"></img>
                <h3>A Detailed Description</h3>
                <p>Provide a thorough description of your watch, including its condition, brand, and any unique features. Transparency is key, especially for pre-owned pieces, to build trust with buyers.</p>
              </div>
            </Grid>
          </Grid>
        </section>

      <Footer />
    </div>
  );
}

export default Index;
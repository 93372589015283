import React from 'react'
import watch from '../../../Assets/watch.png';
import './style.css';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
const TrackRealTime = () => {
  return (
    <>
      <div className='section2 realtime_section'>
          {/* <div className='row2'> */}
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{margin:'auto'}}>
              <Grid className='gridpadding' container style={{paddingLeft:'56px'}}>
                <Grid item xs={12} sm={11} md={10} lg={9} xl={9}>
                  <div>
                    <div>
                      <h2 className='uppercasetext'>Track Real-Time</h2>
                      <h1 className='uppercasetext'>Watch INDEX</h1>
                    </div>
                    <p>Stay ahead with live updates on the luxury watch market. Explore detailed analytics, price trends, and the most sought-after timepieces.
                    </p>
                    <Link to="/index">    <button className='cta_go_to bigscreen_only commonbtnanimation'>TRACK MARKET TRENDS</button></Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{margin:'auto'}}>
              <div className='img2'>
                <img src={watch} alt='watchprice' />
                <Link to="/index">     <button className='cta_go_to mob_only commonbtnanimation'>GO TO INDIAN WATCH INDEX</button></Link>
              </div>
            </Grid>
          </Grid>
          {/* </div> */}
      </div>
    </>
  )
}
export default TrackRealTime
import React,{useEffect} from 'react'
import iwc from '../../../Assets/iwc.png';
import { Grid } from '@mui/material';
import './style.css'; 
import { Link } from 'react-router-dom';
import { truncateText } from '../../../helpers';
const AboutUs = ({about}) => {
//   useEffect(() => {
//     console.log('hgh',window.location.href.split('#').length);
//     if(window.location.href.split('#').length > 1){
//       const section = document.querySelector('#section-black');
//       section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
//       console.log('section',section);
//     } 
//     if(window.location.hash.includes('section-black')){
//       let Menu = document.querySelector('#section-black');
// let B = document.getElementById('section-black');
// Menu.scroll({ left: B.offsetLeft, behavior: 'smooth' });

//     }

//   }, [window.location.href]);	
  return (
    <div>
      <section className='section-black' id='section-black'>
        <div className='heading-h1'>
          <h3 className='uppercasetext'>Buy. Sell. Trade. Trust
          </h3>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='grid_cols'>
            <div className='img3'>
              <img src={iwc} alt='watchprice'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='grid_cols' style={{margin:'auto'}}>
            <Grid container style={{justifyContent:'center'}}>
              <Grid item xs={12} sm={12} md={10} lg={9} xl={9}>
                <div>
                  <div>
                    <h1 className='uppercasetext'>About Us</h1>
                  </div>
                  <p>
                   {about ? truncateText(about,50 ): "no about desc"}
                  </p>
                  <Link to="/products">  <button className='btn3 commonbtnanimation'>EXPLORE TIMELESS ELEGANCE 
                  </button></Link>
                </div>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      </section>
    </div>
  )
}

export default AboutUs

import React, { useState, useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Accordion from '@mui/material/Accordion';
import { Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styles from "../styles/styles";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="sleek_banner">
        <h1>Frequently Asked Questions</h1>
    </div>
    <div className="faqpage whitenav">
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
    </>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState('faq');

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className={`${styles.section} mb-6`}>
      {/* <h1 className='page-heading gradient-heading desk-faq-heading'><span className="uppercase font-weight-600 dark-gray-color">Frequently Asked Questions</span></h1> */}
      {/* <h1 className='page-heading gradient-heading mob-faq-heading'><span className="font-weight-600 dark-gray-color">FAQs</span></h1> */}
          
      <Grid className="mt-4" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
        {/* <Grid item md={3} sm={4} xs={12}>
          <ul className="faq_category">
            <li onClick={()=>toggleTab('faq')} className={activeTab === 'faq' ? "active" : ""}>FAQ<span>/Help</span></li>
            <li onClick={()=>toggleTab('sell')} className={activeTab === 'sell' ? "active" : ""}>Sell</li>
            <li onClick={()=>toggleTab('buy')} className={activeTab === 'buy' ? "active" : ""}>Buy</li>
            <li onClick={()=>toggleTab('account')} className={activeTab === 'account' ? "active" : ""}><span>My </span>Account</li>
            <li onClick={()=>toggleTab('returns')} className={activeTab === 'returns' ? "active" : ""}>Return & Exchange</li>
          </ul>
        </Grid> */}
        <Grid item md={12} sm={12} xs={12}>
          <div>
            <div>
              <Accordion defaultExpanded={true} className="main_accordion">
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What is Indian Watch Connoisseur?</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion_dets">
                  <Typography>
                  At I.W.C., we are more than a platform—we’re a trusted partner for collectors, enthusiasts, and sellers in the luxury watch community. Combining unwavering authenticity, exceptional craftsmanship, and tailored services, we redefine how luxury watches are bought, sold, and traded.
Our mission is to provide a seamless and secure experience, connecting clients to the world’s most coveted watches while setting a new standard in the luxury watch industry. 
                  </Typography>
                </AccordionDetails>
              </Accordion>
             {/* single faq starts  */}
              <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What is the Indian Watch Index on I.W.C?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  The Indian Watch Index is a cutting-edge platform offering real-time insights into the values and trends of luxury watches. Track the performance of the most iconic and sought-after timepieces, and access up-to-date information on pricing, market movements, and the latest trends. Perfect for collectors, enthusiasts, and buyers looking to stay ahead in the world of luxury watches.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
              <Accordion className="main_accordion">
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">Is the watch appraisal free on Indian Watch Connoisseur?</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Yes, our watch appraisal service is absolutely free. Simply provide a few key details about your timepiece, and we’ll deliver an accurate and reliable valuation at no cost.
                  </Typography>
                </AccordionDetails>
              </Accordion>

               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What kind of watches does I.W.C. specialize in?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  We specialize in luxury timepieces from the world’s most iconic brands, featuring limited editions and vintage treasures. Whether you're an avid collector or exploring your first luxury purchase, our meticulously curated collection is designed to captivate every watch enthusiast.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How can I buy a watch on Indian Watch Connoisseur?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Browse our listings, select your desired watch, and proceed with the secure online purchase process. Payments are held safely in escrow until you receive your watch. For assistance, our team is always available to guide you through the process.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How do I sell my watch on Indian Watch Connoisseur?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  1. Create a free listing with key details, photos, and a detailed description.<br/>
2. Enter your watch's reference number for auto-filled details using our Listing Assistant.<br/>
3. Upload up to 10 images, including 2 additional photos as proof of ownership.<br/>
4. Enjoy secure transactions and fast payment within 7 days, minus a small commission.<br/>

                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How much does it cost to sell a watch on Indian Watch Connoisseur?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Creating a listing is free. We charge a commission fee based on the watch's value: 5% for watches under 5 lakhs, 8% for watches between 5 to 10 lakhs, and 10% for watches above 10 lakhs. The fee is deducted during the payout after a successful sale.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What happens if my watch doesn’t sell?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  If your watch doesn’t sell within the listing period, you can relist it for free or adjust the price to attract more buyers. Our team is here to offer guidance if needed.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How quickly will I receive payment after selling my watch?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  You will receive your payment within 7 days after the buyer has confirmed receipt of the watch, ensuring a smooth and secure transaction process.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What payment methods do you accept?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  We accept a variety of secure payment options, including credit card, bank transfer, and other trusted methods. Currently, our payment platform partner is experiencing some technical issues, which they are actively working to resolve.
For further details or assistance, please don’t hesitate to reach out to our team—we’re here to help!
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What is your return policy?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  To initiate a return, please contact us directly via email or WhatsApp. Returns are evaluated on a case-by-case basis, and eligibility depends on the condition of the watch, seals, and packaging. Ensure the return shipment is fully insured and complies with the provided return deadline.
Our team is here to guide you through the process and ensure a smooth experience.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">Are all watches on your platform authentic?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  We conduct a thorough and rigorous authentication process for every watch, verifying its quality, originality, and condition. Our trusted legacy ensures buyers receive only 100% genuine timepieces, offering complete peace of mind with every purchase.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How does I.W.C. ensure the safety of buyers and sellers?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Our secure escrow payment system, rigorous authentication processes, and trusted shipping partners ensure complete safety for both buyers and sellers throughout the transaction.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How does I.W.C. protect my personal and financial information?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  We prioritize your privacy and security by using advanced encryption technologies and adhering to strict data protection policies. Your personal and financial information is always safe with us.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
               {/* single faq starts  */}
               <Accordion className="main_accordion">
                
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">Is there a customer support team I can contact?</Typography>
                </AccordionSummary>

                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Yes, our dedicated customer support team is available 24/7 to assist you with any inquiries or issues.
                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* single faq ends */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQPage;

import React, { useEffect, useState } from "react";
import Cart from "../components/Shopping/Cart";
import Shipping from "../components/Shopping/Shipping";
import Payment from "../components/Shopping/Payment";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { Grid } from "@mui/material";
import clipboard from "../Assets/clipboard.png";
import emptycart from "../Assets/newImages/emptycart.png";
import { Link } from "react-router-dom";
import OurPromises from "../components/Route/OurPromises/OurPromises";
import { formatIndianCurrency } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../redux/actions/cart";
import { backend_url } from "../Path";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState("");
  const [loading, setLoading] = useState(false);
  const { cart } = useSelector((state) => state.cart);
  const { shipping } = useSelector((state) => state.shipping);
  const id = useSelector((state) => state.userData.userId);
  const loggedIn = useSelector((state) => state.userData.loggedIn);

  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      dispatch(removeFromCart(data[i]));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    var trail_url = url.split("/").pop();
    setPage(trail_url);

    if (
      (trail_url  === "shipping" && !loggedIn) ||
      (trail_url  === "payment" && !loggedIn)
    ) {
      navigate("/login");
      toast.error("You must be logged In");
    }
  });
  const totalPrice = cart.reduce(
    // (acc, item) => acc + item.qty * parseFloat((item.price).replace(/,/g, '')),
    (acc, item) => acc + item.qty * parseFloat((item.price ? item.price : item.api_price)),
    0
  );
  const checkForEmpty = () => {
    if(shipping.first_name === '' || shipping.first_name === undefined){
      toast.error("Please fill First name");
    }else if(shipping.last_name === '' || shipping.last_name === undefined){
      toast.error("Please fill Last name");
    }else if(shipping.email === '' || shipping.email === undefined){
      toast.error("Please fill Email address");
    }else if(shipping.contact === '' || shipping.contact === undefined){
      toast.error("Please fill Contact number");
    }else if(shipping.address === '' || shipping.address === undefined){
      toast.error("Please fill address");
    }else if(shipping.city === '' || shipping.city === undefined){
      toast.error("Please fill city");
    }else if(shipping.state === '' || shipping.state === undefined){
      toast.error("Please fill state");
    }else if(shipping.country === '' || shipping.country === undefined){
      toast.error("Please fill country");
    }else if(shipping.zipcode === '' || shipping.zipcode === undefined){
      toast.error("Please fill zipcode");
    }else{
      navigate("/checkout/payment");
    }
    return false;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const cartJSON = JSON.stringify(cart);
    const shippingJSON = JSON.stringify(shipping);
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", id);
    formDataToSend.append("order_value", totalPrice);
    formDataToSend.append("order_data", cartJSON);
    formDataToSend.append("shipping_data", shippingJSON);
    // console.log('cart',cart);
    fetch(`${backend_url}orders/create_order.php`, {
      method: "POST",
      body: formDataToSend, // Send FormData with files
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          console.log("Success:", data);
          toast.success(data.message);
          removeFromCartHandler(cart);
          navigate("/order/success");
        } else {
          console.error("Error:", data.message);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getData = async (view_id) => {
    fetch(`${backend_url}/products/fetch_data.php?id=${view_id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        console.log(response.product);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };
  return (
    <div className="parent_div cart_page whitenav">
      <div className="sleek_banner">
        <h1>CHECKOUT</h1>
      </div>
      <Header activeHeading={1} />
      <section className="cartsection">
        {cart && cart.length === 0 ? (
          <div className="my-5 emptycarttdiv" style={{ marginBottom: "50px" }}>
            <img
              className="mx-auto"
              alt="emptycart_image"
              style={{ maxWidth: "150px" }}
              src={emptycart}
            />
            <h3
              className="text-center my-5"
              style={{ fontSize: "40px", fontWeight: 500, color: "#2c2c2c" }}
            >
              Your cart is empty
            </h3>
            <button className="commonbtnanimation black_bg_animation" style={{width: '200px', display:'block', margin: '0 auto', height: '48px', fontSize: '16px', background: '#1b1b1b', color: '#ffffff'}} onClick={() => navigate('/products')}>
              Go to Products
            </button>
          </div>
        ) : (
          <Grid container className="outerr" rowSpacing={1} columnSpacing={{ xs: 1, sm: 7, md: 9, lg: 10 }}>
            <Grid item lg={8} md={7} sm={7} xs={12}>
              {page === "cart" && <Cart />}
              {page === "shipping" && <Shipping />}
              {page === "payment" && <Payment />}
            </Grid>
            <Grid item lg={4} md={5} sm={5} xs={12}>
              <div className="cart_summary_div">
                <h4>
                  <span
                    style={{
                      display: "inline-block",
                      verticalAlign: "bottom",
                      marginRight: "8px",
                    }}
                  >
                    <img
                      src={clipboard}
                      alt="ordersummary"
                      style={{ width: "27px" }}
                    />
                  </span>
                  Order Summary
                </h4>
                <div className="pricings">
                  <div className="row">
                    <h5>Order Value</h5>
                    <span> {formatIndianCurrency(totalPrice)}</span>
                  </div>
                  <div className="row">
                    <h5>Shipping</h5>
                    <span>Free</span>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid",
                      paddingTop: "8px",
                      marginTop: "8px",
                      marginBottom: 0,
                    }}
                  >
                    <h5 style={{ fontWeight: 600 }}>Grand Total</h5>
                    <span style={{ fontWeight: 600 }}>
                      {formatIndianCurrency(totalPrice)}
                    </span>
                  </div>
                </div>
                <div>
                  {page === "cart" && (
                    <Link
                      className="checkoutbtn commonbtnanimation"
                      to="/checkout/shipping"
                    >
                      Proceed to Checkout
                    </Link>
                  )}
                  {page === "shipping" && (
                    <button className="checkoutbtn commonbtnanimation" onClick={checkForEmpty}>
                      Proceed to Payment
                    </button>
                  )}
                  {page === "payment" &&
                    (loading ? (
                      <div className="text-center mt-5">
                        <CircularProgress />
                      </div>
                    ) : (
                      <a
                        className="checkoutbtn commonbtnanimation"
                        onClick={handleSubmit}
                      >
                        Place order
                      </a>
                    ))}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </section>
      {cart && cart.length > 0 && (
        <div className="statss">
          <OurPromises />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CheckoutPage;

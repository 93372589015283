import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import { RxCross1 } from "react-icons/rx";
// import { TiArrowDown } from "react-icons/ti";
// import ResponsivePagination from 'react-responsive-pagination';
// import 'react-responsive-pagination/themes/classic.css';
// import CircularProgress from "@mui/material/CircularProgress";
// import {  useSelector } from "react-redux";
// import financial from '../../../Assets/financialexpress.png';
// import times from '../../../Assets/lokamt.png';
// import abp from '../../../Assets/abp.png';
// import silicon from '../../../Assets/silicon.png';
// import rew from '../../../Assets/rewired.png';
import { backend_url } from '../../../Path';
import './style.css';
// import { Link } from 'react-router-dom';

//new code
const AllEditorialIcons = () => {
  const navigate = useNavigate();
  // const [isSubmitting, setisSubmitting] = useState(0);
  // const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  // const [open, setOpen] = useState(false);
  // const [dropIndex, setDropIndex] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const [editorials, setEditorials] = useState([]); 
  // const [deleteId, setDeleteId] = useState(null); 
  const [noRecordsFound, setNoRecordsFound] = useState(false); 
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    // if (!adminLoggedIn) {
    //   navigate('/admin/login');
    //   toast.error('You must be logged in');
    // }
    setLoading(true);
    const fetchEditorials = async () => {
      try {
        const limit = 15;
        const response = await fetch(`${backend_url}editorials/editorials.php?limit=${limit}&page=${currentPage}`);
        const result = await response.json();
        
        if (result && result.editorials) {
          setEditorials(result.editorials);
          setTotalPages(Math.ceil(result.totalRecords / limit));
          setNoRecordsFound(result.editorials.length === 0); 
        } else {
          console.error("Failed to fetch editorials");
          setNoRecordsFound(true); 
        }
      } catch (error) {
        console.error("An error occurred while fetching editorials:", error);
        setNoRecordsFound(true); 
      } finally {
        setLoading(false); // Ensure loader is hidden after fetch is complete
      }
    };
  
    fetchEditorials();
  }, [currentPage]);

  return (
    <>
      <div className='NA_section marquee'>
        <div className="NewsArticle flex justify-between w-full track">
            {editorials && editorials.length > 0 && editorials.map((editorial,index)=>
             <Link to={editorial.url}> 
                <div className={index === editorials.length-2 || index === editorials.length-1 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
                  <img src={`${backend_url}editorials/uploads/${editorial.image_url}`} alt="" /> 
                </div>
              </Link>
            )}
            {editorials && editorials.length > 0 &&  editorials.map((editorial,index)=>
              <Link to={editorial.url}> 
                 <div className={index === editorials.length-2 || index === editorials.length-1 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
                  <img src={`${backend_url}editorials/uploads/${editorial.image_url}`} alt="" /> 
                </div>
            </Link>
            )}

        </div>
      </div>
    </>
  )
}
export default AllEditorialIcons


// old code

// import React from 'react'
// import financial from '../../../Assets/financialexpress.png';
// import times from '../../../Assets/lokamt.png';
// import abp from '../../../Assets/abp.png';
// import silicon from '../../../Assets/silicon.png';
// import rew from '../../../Assets/rewired.png';
// import { backend_url } from '../../../Path';
// import './style.css';

// const NewsArticle = ({editorials}) => {
//   return (
//     <>
//       <div className='NA_section marquee'>
//         <div className="NewsArticle flex justify-between w-full track">
//             {editorials && editorials.map((edit,index)=>
//               <div className={index === 3 || index === 4 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
//                 <img src={`${backend_url}editorials/uploads/${edit}`} alt="" />
//               </div>
//             )}
//             {editorials && editorials.map((edit,index)=>
//               <div className={index === 3 || index === 4 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
//                 <img src={`${backend_url}editorials/uploads/${edit}`} alt="" />
//               </div>
//             )}
//           {/* <div className="flex items-start naimgb">
//             <img src={financial} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={abp} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={silicon} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={rew} alt="" />
//           </div>
//           <div className="flex items-start naimgb">
//             <img src={times} alt="" />
//           </div>
//           <div className="flex items-start naimgb">
//             <img src={financial} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={abp} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={silicon} alt="" />
//           </div>
//           <div className="flex items-start naimg">
//             <img src={rew} alt="" />
//           </div> */}
//         </div>
//       </div>
//     </>
//   )
// }

// export default NewsArticle
//old code

// import React, { useState, useEffect } from 'react';
// import { Grid, Box } from '@mui/material';
// import './style.css';
// import { useNavigate } from 'react-router-dom';
// import { backend_url } from '../../Path';
// import CircularProgress from "@mui/material/CircularProgress";

// function Blogs() {
//   const navigate = useNavigate();
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [blogs, setBlogs] = useState([]);
//   const [noRecordsFound, setNoRecordsFound] = useState(false);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     setLoading(true);
//     const fetchBlogs = async () => {
//       try {
//         const limit = 6;
//         const response = await fetch(`${backend_url}blogs/blogs.php?type=get&limit=${limit}&page=${currentPage}`);
//         const result = await response.json();
//         console.log("API Response:", result);
//         if (result && result.data) {
//           setBlogs((prevBlogs) => [...prevBlogs, ...result.data]);
//           setTotalPages(Math.ceil(result.total_blogs / limit));
//           setNoRecordsFound(result.data.length === 0);
//         } else {
//           console.error("Failed to fetch blogs");
//           setNoRecordsFound(true);
//         }
//       } catch (error) {
//         console.error("An error occurred while fetching blogs:", error);
//         setNoRecordsFound(true);
//       }
//       finally{
//         setLoading(false);
//       }
//     };
//     fetchBlogs();
//   }, [currentPage]);
//   function truncateText(text, wordLimit = 10) {
//     const words = text.split(/\s+/);
//     if (words.length > wordLimit) {
//       return words.slice(0, wordLimit).join(' ') + '...';
//     }
//     return text;
//   }
//   const handleViewMore = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//     // Navigate to a specific blog detail page with ID 2
//     // navigate(`/blog-details/2`);
//   };
//   const blogDetail = (id) => {
//     navigate(`/blog-details/${id}`);
//   };
//   return (
//     <>
//       <div className="sleek_banner">
//         <h1>BLOGS</h1>
//       </div>
//       <div className="contact-container">
//         <Box className="blogs-page">
//           <Grid container>
//             {!loading  ?
//             blogs.map((blog) => (
//               <Grid xl={4} lg={4} md={6} sm={6} xs={12} className='blog-div' onClick={() => blogDetail(blog.id)} key={blog.id}>
//                 <img className="blog-img" src={`${backend_url}blogs/blog_uploads/${blog.image}`} alt={blog.title} />
//                 <div className='blog-detail'>
//                   <h2>{blog.title}</h2>
//                   <p
//   dangerouslySetInnerHTML={{
//     __html: blog ? truncateText(blog.description, 20) :                 
//       <CircularProgress color="inherit" />
//     ,
//   }}
// />
//                   <span>Jan 2024</span>
//                   <span className="read_more">Read more</span>
//                 </div>
//               </Grid>
//             ))
//           :<CircularProgress color="inherit" />
          
//           }
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} className='view-more-blogs'>
//               {currentPage < totalPages && (
//                 <button className='commonbtnanimation' onClick={handleViewMore}>
//                   View More
//                 </button>
//               )}
//             </Grid>
//           </Grid>
//         </Box>
//       </div>
//     </>
//   );
// }
// export default Blogs;

import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { backend_url } from '../../Path';
import CircularProgress from "@mui/material/CircularProgress";

function Blogs() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const limit = 6;
        const response = await fetch(`${backend_url}blogs/blogs.php?type=get&limit=${limit}&page=${currentPage}`);
        const result = await response.json();
        console.log("API Response:", result);
        if (result && result.data) {
          setBlogs((prevBlogs) => [...prevBlogs, ...result.data]);
          setTotalPages(Math.ceil(result.total_blogs / limit));
          setNoRecordsFound(result.data.length === 0);
        } else {
          console.error("Failed to fetch blogs");
          setNoRecordsFound(true);
        }
      } catch (error) {
        console.error("An error occurred while fetching blogs:", error);
        setNoRecordsFound(true);
      }
      finally{
        setLoading(false);
      }
    };
    fetchBlogs();
  }, [currentPage]);
  function truncateText(text, wordLimit = 10) {
    const words = text.split(/\s+/);
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  }
  const handleViewMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    // Navigate to a specific blog detail page with ID 2
    // navigate(`/blog-details/2`);
  };
  const blogDetail = (id) => {
    navigate(`/blog-details/${id}`);
  };
  return (
    <>
      <div className="sleek_banner">
        <h1>BEHIND THE DIAL</h1>
      </div>
      <div className="contact-container">
      <Box className="blogs-page">
  <Grid container>
    {!loading ? (
      blogs.map((blog) => (
        <Grid 
          xl={4} 
          lg={4} 
          md={6} 
          sm={6} 
          xs={12} 
          className='blog-div' 
          onClick={() => blogDetail(blog.id)} 
          key={blog.id}
        >
          <img 
            className="blog-img" 
            src={`${backend_url}blogs/blog_uploads/${blog.image}`} 
            alt={blog.title} 
          />
          <div className='blog-detail'>
            <h2>{blog.title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: blog ? truncateText(blog.description, 20) : '',
              }}
            />
             <span>{new Date(blog.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</span>
            <span className="read_more">Read more</span>
          </div>
        </Grid>
      ))
    ) : (
      <Grid 
        item 
        xs={12} 
        style={{
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center',     
          height: '100vh',
        }}
      >
        <CircularProgress color="inherit" />
      </Grid>
    )}
    <Grid xl={12} lg={12} md={12} sm={12} xs={12} className='view-more-blogs'>
      {currentPage < totalPages && (
        <button className='commonbtnanimation' onClick={handleViewMore}>
          View More
        </button>
      )}
    </Grid>
  </Grid>
</Box>

      </div>
    </>
  );
}
export default Blogs;

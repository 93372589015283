import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  HomePage,
  FAQPage,
  // CheckoutPage,
  OrderSuccessPage,
  NotFound,
  ProductDetailsPage,
  VerifyEmailPage,
  NewProfilePage,
  ForgotPwdPage,
  ResetPwdPage,
  SellIndexPage,
  SellWatchDetailFormPage,
  ContactUsPage,
  BlogsPage,
  BlogDetailsPage,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
  UserIdentityPage,
  PaymentSettingsPage,
  ProductListingPage,
  SignupVerificationPage
} from "./routes/Routes.js";

import {
  AdminLoginPage,
  AdminDashboardUsers,
  AdminBrandsList,
  AdminUpdateBrand,
  AdminGeneralSettings,
  AdminPlatformSettings,
  AdminTransactionsList,
  AdminEditorialIcons,
  AdminContactLeadsPage,
  AdminDashboard,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEditUser,
  AdminAddProduct,
  AdminUpdateProduct,
  AdminAddEditorial,
  AdminUpdateEditorial,
  AdminChangePwd,
  AdminBlogs,
  AdminUpdateBlogs,
  AdminAddBlogs,
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getAllProducts } from "./redux/actions/product";
// import { getAllEvents } from "./redux/actions/event";
// import axios from "axios";
// import { server } from "./server";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import ProductDetailsNew from "./components/Route/ProductDetail/ProductDetails.jsx";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
// import ForgotPwd from "./components/ForgotPwd/ForgotPwd.jsx";
// import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy.jsx";
import IndexPage from "./pages/IndexPage.jsx";
import IndexDetailPage from "./pages/IndexDetailPage.jsx";
import CheckoutPage from "./pages/CheckoutPage.jsx";
import ViewProductsPage from "./pages/ViewProductsPage.jsx";
import OrdersPage from "./pages/OrdersPage.jsx";
import OrderDetailsPage from "./pages/OrderDetailsPage.jsx";
import AppraiselPage from "./pages/AppraiselPage.jsx";
import StoreProvider from './store';

Amplify.configure(awsconfig);

const App = () => {
  // const [stripeApikey, setStripeApiKey] = useState("");

  // async function getStripeApikey() {
  //   const { data } = await axios.get(`${server}/payment/stripeapikey`);
  //   setStripeApiKey(data.stripeApikey);
  // }
  useEffect(() => {
    // Store.dispatch(loadUser());
    // Store.dispatch(loadSeller());
    // Store.dispatch(getAllProducts());
    // Store.dispatch(getAllEvents());
    // getStripeApikey();
  }, []);

//   return (

//   );
// };

const correctPassword = "secret123"; // The correct password
const [password, setPassword] = useState('');
const [isAuthenticated, setIsAuthenticated] = useState(false);
const [errorMessage, setErrorMessage] = useState('');

// Handle password input change
const handlePasswordChange = (event) => {
  setPassword(event.target.value);
};

// Handle form submission
const handleSubmit = (event) => {
  event.preventDefault();
  if (password === correctPassword) {
    setIsAuthenticated(true);
    setErrorMessage('');
  } else {
    setErrorMessage('Incorrect password! Please try again.');
  }
};

// If authenticated, show the app content, otherwise show the password prompt
return (
  <div className="App">
    {/* {isAuthenticated ? ( */}
      <div>
        {/* //     // <StoreProvider> */}
       <BrowserRouter>
         <Routes>
           <Route path="/" element={<HomePage />} />
           <Route path="/login" element={<LoginPage />} />
           <Route path="/forgot-pwd" element={<ForgotPwdPage />} />
           <Route path="/sign-up" element={<SignupPage />} />
           <Route path="/verify-email" element={<VerifyEmailPage />} />
           <Route path="/reset-pwd" element={<ResetPwdPage />} />
           <Route path="/user-profile" element={<NewProfilePage />} />
           <Route path="/user-identity" element={<UserIdentityPage />} />
           {/* <Route path="/user-overview" element={<OverviewPage />} /> */}
           {/* <Route path="/sell" element={<SellPage />} /> */}
           <Route path="/Sell-your-watch" element={<SellIndexPage />} />
           {/* <Route path="/wishlist" element={<WishListPage />} /> */}
           <Route path="/watch-detail-form/" element={<SellWatchDetailFormPage />} />
           {/* <Route path="/notifications" element={<NotificationsPage />} /> */}
           <Route path="/contact-us" element={<ContactUsPage />} />
           <Route path="/blogs" element={<BlogsPage />} />
           <Route path="/blog-details/:id" element={<BlogDetailsPage />} />
           <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
           <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
           <Route path="/payment-settings" element={<PaymentSettingsPage />} />
           <Route path="/product-listing/" element={<ProductListingPage />} />
           <Route path="/admin-login" element={<AdminLoginPage />} />
           {/* <Route
//             path="/activation/:activation_token"
//             element={<ActivationPage />}
//           /> */}
           <Route path="/index" element={<IndexPage />} />
           <Route path="/index-detail" element={<IndexDetailPage />} />
           <Route path="/checkout/cart" element={<CheckoutPage />} />
           <Route path="/checkout/shipping" element={<CheckoutPage />} />
           <Route path="/checkout/payment" element={<CheckoutPage />} />
           {/* <Route
//             path="/seller/activation/:activation_token"
//             element={<SellerActivationPage />}
//           /> */}
           <Route path="/products" element={<ViewProductsPage />} />
           <Route path="/appraisal" element={<AppraiselPage />} />
           <Route path="/my-orders" element={<OrdersPage />} />
           <Route path="/order-details/:orderId" element={<OrderDetailsPage />} />
           {/* <Route path="/products" element={<ProductsPage />} /> */}
           {/* <Route path="/product/:id" element={<ProductDetailsPage />} /> */}
           <Route path="/productdetail" element={<ProductDetailsPage />} />
           <Route path="/faq" element={<FAQPage />} />
          
           <Route path="/order/success" element={<OrderSuccessPage />} />
           <Route path="/verify" element={<SignupVerificationPage />} />
           <Route path="*" element={<NotFound />} />
           {/* Admin Routes */}
           <Route
            path="/admin/login"
             element={
               <AdminLoginPage />
             }
           />
           <Route
             path="/admin/dashboard"
             element={
               <AdminDashboard />
             }
           />
           <Route
             path="/admin-users"
             element={
               <AdminDashboardUsers />
             }
           />
           <Route
             path="/admin-brands"
             element={
               <AdminBrandsList />
             }
           />
           <Route
             path="/edit-brand"
             element={
               <AdminUpdateBrand />
             }
           />
           <Route
             path="/edit-user"
             element={
               <AdminDashboardEditUser />
             }
           />
          
           <Route
             path="/admin-orders"
             element={
               <AdminDashboardOrders />
             }
           />
           <Route
             path="/admin-products"
             element={
               <AdminDashboardProducts />
             }
           />
           <Route
             path="/general-settings"
             element={
               <AdminGeneralSettings />
             }
           />

          <Route
             path="/change-pwd"
             element={
               <AdminChangePwd />
             }
           />            

            
           <Route
             path="/add-product"
             element={
               <AdminAddProduct />
             }
           />
          
           <Route
             path="/update-product/:id"
             element={
               <AdminUpdateProduct />
             }
           />

           <Route
             path="/add-editorial"
             element={
               <AdminAddEditorial />
             }
           />
          
           <Route
             path="/update-editorial"
             element={
               <AdminUpdateEditorial />
             }
           />

           <Route path="/update-editorial/:id" element={<AdminUpdateEditorial/>} />
           <Route
             path="/platform-settings"
             element={
               <AdminPlatformSettings />
             }
           />   
               <Route
             path="/all-blogs"
             element={
               <AdminBlogs/>
             }
           />
              <Route
             path="/add-Blog"
             element={
               <AdminAddBlogs />
             }
           />
          
           <Route
             path="/update-Blogs/:id"
             element={
               <AdminUpdateBlogs />
             }
           />
           <Route
             path="/transactions-list"
             element={
               <AdminTransactionsList />
             }
           />
           <Route
             path="/editorial-icons"
             element={
               <AdminEditorialIcons />
             }
           />
           <Route
             path="/all-contact-leads"
             element={
               <AdminContactLeadsPage />
             }
           />
       

           {/* <Route
             path="/admin-sellers"
             element={
               <ProtectedAdminRoute>
                 <AdminDashboardSellers />
               </ProtectedAdminRoute>
             }
           />
           <Route
             path="/admin-events"
             element={
               <ProtectedAdminRoute>
                 <AdminDashboardEvents />
               </ProtectedAdminRoute>
             }
           />
           <Route
             path="/admin-withdraw-request"
             element={
               <ProtectedAdminRoute>
                 <AdminDashboardWithdraw />
               </ProtectedAdminRoute>
             }
           /> */}
         </Routes>
         <ToastContainer
           position="bottom-center"
           autoClose={5000}
           hideProgressBar={false}
           newestOnTop={false}
           closeOnClick
           rtl={false}
           pauseOnFocusLoss
           draggable
           pauseOnHover
           theme="dark"
         />
       </BrowserRouter>
     {/* // </StoreProvider> */}
      </div>
    {/* ) : ( */}
      {/* <div style={{background: 'radial-gradient(#795548, transparent)', height:'1000px'}}>
        <h2 style={{padding: '20px'}}>This Website is under Password Protection. Only users with password can access this. </h2>
        <form onSubmit={handleSubmit} style={{padding: '20px'}}>
          <label>
            Enter Password:
            <input 
            style={{padding: '10px'}}
            id="inpt"
              type="password" 
              value={password} 
              onChange={handlePasswordChange} 
              required
            />
          </label>
          <button style={{padding: '10px', background: '#090909', width: '150px', color: '#ffffff'}} type="submit" id="sbmt">Submit</button>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div> */}
    {/* )} */}
  </div>
);
}

export default App;

import React from 'react'
import insta1 from '../../../Assets/insta1.png';
import insta2 from '../../../Assets/insta2.png';
import insta3 from '../../../Assets/insta3.png';
import insta4 from '../../../Assets/insta4.png';
import insta5 from '../../../Assets/insta5.png';
import arrow from '../../../Assets/arrow_back.png';
import './style.css';


const Instagram = () => {
  return (
 <>
    <section className='section-insta'>
      <div className='heading-row'>
        <h3 className='uppercasetext'>Follow Us</h3>
      </div>
      {/* <div className="flex justify-center w-full">
        <div className="flex  insta-img">
          <img src={insta1} alt="" />
        </div>
        <div className="flex  insta-img">
          <img src={insta2} alt=""/>
        </div>
        <div className="flex  insta-img">
          <img src={insta3} alt=""/>
        </div>
        <div className="flex  insta-img hide_on_mobile">
          <img src={insta4} alt=""/>
        </div>
        <div className="flex  insta-img hide_on_mobile">
          <img src={insta5} alt=""/>
        </div>
      </div> */}
      {/* <div class="elfsight-app-e7ec98da-892a-4ead-bfb4-3966ddc3d5d0" data-elfsight-app-lazy></div> */}
    <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.10/iframeResizer.min.js"></script>
    <iframe onload="iFrameResize(this)" src="https://58649d726e5a412e8911a2fc78c8264f.elf.site" style={{border:'none',width:'100%',height:'500px', padding: '0px 5px'}}></iframe>

{/* <script src="https://static.elfsight.com/platform/platform.js" async></script>
<div class="elfsight-app-58649d72-6e5a-412e-8911-a2fc78c8264f" data-elfsight-app-lazy></div> */}
    </section>
</>
  )
}

export default Instagram

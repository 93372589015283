import React, { useState,useRef,useEffect } from 'react';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';
import whatsap from "../../Assets/whatsap.png";
import step1 from "../../Assets/step1.png";
import step2 from "../../Assets/step2.png";
import step3 from "../../Assets/step3.png";
import step4 from "../../Assets/step4.png"
import why3 from "../../Assets/newImages/why3.png";
import tip1 from "../../Assets/newImages/tip1.png";
import tip2 from "../../Assets/newImages/tip2.png";
import tip3 from "../../Assets/newImages/tip3.png";
import search from "../../Assets/newImages/search.png";
import i_gen_price from "../../Assets/i_gen_price.svg";
import { backend_url } from "../../Path";
import { Link,useNavigate } from "react-router-dom";
import {formatIndianCurrency} from '../../helpers';

export default function Search() {
  let navigate = useNavigate();
  const [showDropBox, setShowDropBox] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [commission, setCommission] = useState(0);
  const [products, setProducts] = useState([]);
  const search_input = useRef(null);

  const setValue = async(value,id) => {
    search_input.current.value  = value;
    search_input.current.id  = id;
    setShowDropBox(false);
  }

  const handleSearch = async(e) => {
    e.preventDefault();
    navigate('/watch-detail-form#'+search_input.current.id);
  };

  const getSearchData = async(e) => {
    e.preventDefault();
    setProducts([]);
    var search_text = e.target.value;
    if(search_text.length  > 3){
      const data = new FormData();
      data.append('searchText', search_text);

      fetch(backend_url+"products/search_products.php", {
        method: "POST",       
        body: data				
      })
      .then(res => res.json())
      .then(response=>{
        if(response.length > 0){
          setShowDropBox(true);
          setProducts(response);
        }else{
          setShowDropBox(false);
          setProducts(response);
        }
      }
      )
      .catch(err => {
        console.log('err',err);
      })
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "price") { 
      let salesPrice = parseFloat(value) || 0;
        var comm = 5;
        if(salesPrice <= 500000){
          // comm = 5;
          comm = localStorage.getItem('commission');
        }else if(salesPrice > 500000 && value <= 1000000){
          // comm = 8;
          comm = localStorage.getItem('commission_one');
        }else{
          // comm = 10; 
          comm = localStorage.getItem('commission_two');
        }
      // let newCommission = (salesPrice * ((localStorage.getItem('commission')) / 100)).toFixed(2);
      let newCommission = (salesPrice * (comm / 100)).toFixed(2);
      setCommission(newCommission);
      let newRevenue = (salesPrice - newCommission).toFixed(2);      
      setRevenue(newRevenue);
      // console.log('calculation',salesPrice,newCommission,newRevenue);
    }
  };

//  const [variable, setVariable] = useState();
// const how_to_sell = 'how_to_sell';
// window.setTimeout( () => {
//   document.querySelector('#how_to_sell').scrollIntoView();
// }, 1000);
  useEffect(() => {
    if(window.location.href.split('#').length > 1){
      window.setTimeout( () => {
        document.querySelector('#how_to_sell').scrollIntoView();
      }, 1000);
        
    }    
  }, []);	

  return (
    <div className='main_div sellindex_main home_main_div'>
      <div className='upper_area'>
        <Header />
        <div className={`hero-section banner index-section-banner sell-index-section-banner`}>
          <div className="hero-heading">
            <h2><span>Instant Evaluation. Exceptional Value</span></h2>
            <p className="hero-text1">GET YOUR WATCH EVALUATED</p>
            <p className="hero-text2">IMMEDIATELY</p>
          </div>
          <div className='cta_banner'>
            <p>Sell Your Watch. Anytime, Anywhere. </p>
            <div className="appraisel-form-group search-div" style={{paddingRight:'0px'}}>
              <input type="text" placeholder="Reference number....." ref={search_input} onChange={getSearchData} id=""></input>
              <img src={search}></img>

              {showDropBox &&
              (<div className="sell-index-form-dropdown">
                <ul>
                  {products && products.length > 0 &&
                  products.map(item => (
                    <li onClick={()=>{setValue(item.name,item.id,item,item.reference_number, item.api_price)}} key={item.id}>
                      <Link>
                        <h5>{item.name}</h5>
                        <h6>{item.brand}</h6>
                        <h6>{item.reference_number} - ₹{item.api_price}</h6>
                      </Link>
                    </li>
                  ))}
                  {/* {products && products.length < 0}
                    <li>
                      <Link>
                        <h5>No result found. </h5>
                      </Link>
                    </li> */}
                </ul>
              </div>)}


            </div>
            <button className='commonbtnanimation' onClick={handleSearch}>SEARCH</button>
          </div>
          <a href='web.whatsapp.com' className='whatsap_logo' target='_blank'>
            <img src={whatsap}/> <span>Join Community</span>
          </a>
        </div>
      </div>

      <section className='why-watch-section section-padding'>
        <h1>WHY SELL WITH I.W.C?</h1>
        <Grid container className='products-last-section'>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src='https://cdn.secondmovement.com/static/version1695386739/frontend/Ethos/smnew/en_GB/images/i-privacy.svg' alt="p-images"></img>
              <h3>Privacy Assured</h3>
              <p>Your personal details and sales information remain 100% confidential throughout the process.

</p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={why3} alt="p-images"></img>
              <h3>Maximized Value</h3>
              <p>Leverage our market insights and expert authentication to ensure you receive the best possible price for your watch.
              </p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src={i_gen_price} alt="p-images"></img>
              <h3>Seamless Transactions</h3>
              <p>From evaluation to payment, every step is smooth and hassle-free, with no hidden surprises.
              </p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src='https://cdn.secondmovement.com/static/version1695386739/frontend/Ethos/smnew/en_GB/images/i-wrty.svg' alt="p-images"></img>
              <h3>Prompt Payments</h3>
              <p>Once your watch is authenticated and the deal finalized, payments are processed swiftly—no delays.</p>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='guide-section section-padding' id="how_to_sell">
        <h1 className='sec-head text-center'>HOW TO SELL WITH I.W.C?</h1>
        <Grid container className='guide-section-div justify-center' rowSpacing={10}>
          <Grid item lg={8} md={10} xs={12}>
            <Grid container className='justify-center' rowSpacing={12}>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>1</span>
                  <h2>Create your free listing</h2>
                  <p>Create your free listing in minutes —just upload photos, add a description, and you're good to go!</p>
                  <img src={step1}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>2</span>
                  <h2>Connect with potential buyers</h2>
                  <p>Join a global network of luxury watch enthusiasts. Our platform connects you with passionate collectors and buyers worldwide who appreciate the true value of your timepiece. 
                  </p>
                  <img src={step2}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>3</span>
                  <h2>Facilitated Secure Transactions</h2>
                  <p>At IWC, we ensure a seamless and reliable payment process as your trusted facilitator. Our platform prioritizes fairness, guaranteeing a win-win experience for both buyers and sellers, so you can trade with complete confidence and peace of mind. 
                  </p>
                  <img src={step3}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>4</span>
                  <h2>Fast payments</h2>
                  <p>Once the transaction is finalized, enjoy prompt payment within a few days, ensuring a hassle-free selling experience for your luxury item.
                  </p>
                  <img src={step4}/>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={12}>
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <img src={step2}/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className='process_div textright'>
                  <span className='mlauto'>2</span>
                  <h2>Find potential buyers</h2>
                  <p>9 million watch lovers search for their dream watch every month on I.W.C. Whether it's a Rolex, Omega, or Breitling – with I.W.C, you're guaranteed to find the ideal buyer for your watch.</p>
                </div>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item md={12}>
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <div className='process_div'>
                  <span>3</span>
                  <h2>Secure Payment</h2>
                  <p>The Chrono24 Escrow Service is designed specifically for the sale of luxury watches and guarantees a secure payment process for everyone.</p>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <img src={step3} className='mlauto'/>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item md={12}>
            <Grid container>
              <Grid className='m-auto' item md={7} sm={6} xs={12}>
                <img src={step4}/>
              </Grid>
              <Grid item md={5} sm={6} xs={12}>
                <div className='process_div textright'>
                  <span className='mlauto'>4</span>
                  <h2>Receive Payment Quickly</h2>
                  <p>Within 7 days of the buyer confirming receipt of the watch, we'll pay you the full amount minus our commission fee.</p>
                </div>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </section>

      <section className='calculate_earnings'>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <h1>EVALUATE YOUR EARNINGS</h1>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className='add_amount'>
              <h2>ADD YOUR PROPOSED AMOUNT</h2>
              <div className='row'>
                <h3>List Price</h3>
                <span>INR</span>
              </div>
              <input type='number' name='price' className='form-control' onChange={handleChange}/>
              {revenue > 0 &&
                <div className='row'>
                  <h3>Your earnings</h3>
                  <div>
                    <span className='sp1'>{formatIndianCurrency(revenue)}</span>
                    <span className='sp2'>(Our Fee: {formatIndianCurrency(commission)})</span>
                  </div>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='our_tips why-watch-section section-padding'>
        <h1>TIPS FOR PRIVATE SELLERS</h1>
        <Grid container className='products-last-section'>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={tip1} alt="p-images"></img>
              <h3>Capture High-Quality Images</h3>
              <p>Make sure to take high-resolution images from multiple angles to showcase your watch’s features. The more images you provide, the better the chances for your listing to appear to potential buyers.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={tip2} alt="p-images"></img>
              <h3>Set a Fair Price</h3>
              <p>Price your watch realistically to increase the likelihood of a sale. Choose an amount, keeping in mind market trends and the watch's condition.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src={tip3} alt="p-images"></img>
              <h3>A Detailed Description</h3>
              <p>Provide a thorough description of your watch, including its condition, brand, and any unique features. Transparency is key, especially for pre-owned pieces, to build trust with buyers. </p>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='how_fees_deducted'>
        <h1 className='text-center'>CALCULATE FEES</h1>
        <Grid container justifyContent='center'>
          <Grid item md={10} sm={12}>
            <div className='deduct_table'>
              <table className='table'>
                <thead>
                  <tr>                      
                    <th>Final Selling Price Of Your Watch (INR)</th>
                    <th>I.W.C's Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&lt; 5 Lakhs</td>
                    <td> {localStorage.getItem('commission')}%</td>
                  </tr>
                  <tr>
                    <td>5 Lakhs - 10 Lakhs</td>
                    <td>{localStorage.getItem('commission_one')}%</td>
                  </tr>
                  <tr>
                    <td>10 Lakhs above</td>
                    <td>{localStorage.getItem('commission_two')}%</td>
                  </tr>
                  {/* <tr>
                    <td>1,000,000+</td>
                    <td>
                      <div>CONTACT US</div>
                      <div>support@indianwatch.com</div>
                      </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='appFaq-section drop-faq_sec'>
        <div className='appFaq-section-div'>
          <Grid container className='appFaq-overlay-div section-padding'>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12} className="outer-guide-div">
              <h1>FREQUENTLY ASKED QUESTIONS</h1>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={12} xs={12} className="outer-guide-div">
              <div className="appFaq-div">
              <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    How do I sell my watch on Indian Watch Connoisseur?
                  </AccordionSummary>
                  <AccordionDetails>
                  {/* Indian Watch Connoisseur takes authenticity seriously. All of our timepieces are inspected under high magnification for any potential signs of fraudulent pieces or parts before they are listed on our platform. */}
                  Selling your watch with us is simple and hassle-free:
                  <ul className="sell_faq_dropdown" style={{listStyle:'square',paddingLeft:'22px',paddingTop:'12px'}}>
                    <li>Create a free listing by providing key details, high-quality photos, and a description of your watch.</li>
                    <li>Reach a global audience of watch enthusiasts.</li>
                    <li>Enjoy secure transactions through our trusted payment protection service.</li>
                    <li>Receive payment quickly—within 7 days of the buyer confirming receipt—minus a small commission.</li>
                  </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Are the watches listed on Indian Watch Connoisseur genuine?
                  </AccordionSummary>
                  <AccordionDetails>
                  {/* Sell your watch through us in just a few, hassle free steps! Register above, or get in touch via Whatsapp with clear photos of your timepiece, the year of the watch, and a description of its condition. */}
                  At Indian Watch Connoisseur, we place a high priority on authenticity. 
                    <ul className="sell_faq_dropdown" style={{listStyle:'square',paddingLeft:'22px',paddingTop:'12px'}}>
                      <li>Listings are verified and include detailed descriptions along with high-quality images.</li>
                      <li>Our trusted network of watch enthusiasts ensures your listings reach the right audience.
                      </li>
                      <li>Secure transactions for both buyers and sellers minimize the risk of fraud.</li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Can you source specific watches for me?

                  </AccordionSummary>
                  <AccordionDetails>
                  Yes! Please send us an query on Source a Watch or Whatsapp with the model you are looking for, and we will do our utmost to source your desired watch.


                  </AccordionDetails>
                </Accordion> */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                   How much does it cost to sell a watch on Indian Watch Connoisseur?

                  </AccordionSummary>
                  <AccordionDetails>
                  Our commission fees are based on the value of your watch:
                  <ul className="sell_faq_dropdown" style={{listStyle:'square',paddingLeft:'22px',paddingTop:'12px'}}>
                      <li>5% for watches valued under ₹5 lakhs</li>
                      <li>8% for watches valued between ₹5 lakhs and ₹10 lakhs
                      </li>
                      <li>10% for watches valued above ₹10 lakhs</li>
                    </ul>
                    We ensure you receive a fair market price for your luxury watch.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                   How long will it take before I receive the payment for my watch?

                  </AccordionSummary>
                  <AccordionDetails>
                  Payments are processed promptly after you accept an offer and complete the necessary paperwork. You can expect to receive your payment shortly after the transaction is confirmed.

                  </AccordionDetails>
                </Accordion>
             
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <Footer />
    </div>
  );
}

const top100Films = [
  { label: 'Rolex' },
  { label: 'Patek Philippe'},
  { label: 'Cartier'},
  { label: 'Audemars Piguet'},
  { label: 'Omega'},
  { label: 'Vacheron Constantin'},
];